import { BallzVault as BallzVaultAbi, ERC20 as ERC20Abi, WCRO as WCROAbi, } from './abis';
import { getContractAddress } from './config';
import { getContract, getProvider } from './utils';
export class SDK {
    constructor(options) {
        this.getContract = (address, abi, signerOrProvider) => {
            if (!signerOrProvider) {
                signerOrProvider = getProvider(this.providerOptions);
            }
            return getContract(address, abi, signerOrProvider);
        };
        this.getContractAddress = (contractName) => {
            if (this.contractAddressMap) {
                return this.contractAddressMap[contractName];
            }
            return getContractAddress(this.providerOptions.chainId, contractName);
        };
        this.getERC20 = (address, signerOrProvider) => this.getContract(address, ERC20Abi, signerOrProvider);
        this.getWCRO = (signerOrProvider) => this.getContract(this.getContractAddress('WCRO'), WCROAbi, signerOrProvider);
        this.getBallz = (signerOrProvider) => this.getContract(this.getContractAddress('Ballz'), ERC20Abi, signerOrProvider);
        this.getBallzVault = (signerOrProvider) => this.getContract(this.getContractAddress('BallzVault'), BallzVaultAbi, signerOrProvider);
        const { providerOptions, contractAddressMap } = options;
        this.providerOptions = providerOptions;
        this.contractAddressMap = contractAddressMap;
    }
}
