import { Refresh } from '@ballz-app/icons';
import { HStack, Icon, Text } from '@chakra-ui/react';

import { useRefetchInterval } from '../hooks/useRefetchInterval';

export const CountDown = ({ refresh }: { refresh?: () => void }) => {
  const [timer] = useRefetchInterval((s) => [s.timer]);

  return (
    <HStack pt="10px" pb="20px" spacing={0}>
      <Text color="#000" fontSize="12px" fontWeight="500" width="120px">
        Price updates in {timer}s
      </Text>
      <Icon
        sx={{
          '@keyframes refresh-rotate': {
            '0%': {
              transform: 'rotate(0deg)',
            },
            '25%': {
              transform: 'rotate(90deg)',
            },
            '50%': {
              transform: 'rotate(180deg)',
            },
            '75%': {
              transform: 'rotate(270deg)',
            },
            '100%': {
              transform: 'rotate(360deg)',
            },
          },
          animationFillMode: 'forwards',
          animation: `refresh-rotate infinite 3s`,
        }}
        as={Refresh}
        boxSize="20px"
        cursor="pointer"
        onClick={() => {
          if (refresh) {
            refresh();
          }
        }}
      />
    </HStack>
  );
};
