import { fontFamily } from '@ballz-app/chakra';
import { currentWallet } from '@ballz-app/wallet';
import { Button, Text } from '@chakra-ui/react';
import { useSwitchNetwork } from '@ui/hooks/useSwitchNetwork';

import { Address } from '../Address';
import { StrokeText } from '../StrokeText';
import { useSwitchNetworkModal } from '../SwitchNetworkModal';
import { useWalletModal } from '../Wallet';

const ConnectText = () => {
  return (
    <StrokeText
      pt={{
        base: 2,
        lg: 3,
      }}
      color="brand.pink"
      fontSize={{
        base: 20,
        lg: 30,
      }}
      lineHeight="16px"
      strokeWidth={{
        base: '4px',
        lg: '5px',
      }}
      strokeColor="#3B254C"
    >
      Connect
    </StrokeText>
  );
};

const WrongNetworkText = () => {
  return (
    <Text
      fontFamily={fontFamily.luckiestGuy}
      pt={{
        base: 2,
        lg: 3,
      }}
      color="brand.white"
      fontSize={{
        base: 14,
        lg: 24,
      }}
      lineHeight="16px"
    >
      Wrong Network
    </Text>
  );
};

export const ConnectBtn = () => {
  const account = currentWallet.useAccount();
  const isWrongNetwork = currentWallet.useIsWrongNetwork();
  const isConnected = currentWallet.useIsConnected();
  const { isLoading: isSwitchingNetwork } = useSwitchNetwork();

  return (
    <Button
      border="2px solid #3B254C"
      borderWidth={{
        base: '2px',
        lg: '3px',
      }}
      borderRadius={{
        base: 20,
        lg: 30,
      }}
      width={{
        base: '142px',
        lg: '230px',
      }}
      height={{ base: '40px', lg: '66px' }}
      boxShadow={{
        base: account ? '2px 2px 0px 0px #FFF' : '2px 2px 0px 0px #FF7BAE',
        lg: account ? '6px 6px 0px 0px #FFF' : '6px 6px 0px 0px #FF7BAE',
      }}
      bg={isWrongNetwork ? 'brand.pink' : 'brand.primary'}
      position="absolute"
      top={{
        base: '42px',
        lg: '30px',
      }}
      right={{
        base: '20px',
        lg: '100px',
      }}
      cursor="pointer"
      zIndex={100}
      fontFamily={{
        base: fontFamily.default,
        lg: fontFamily.luckiestGuy,
      }}
      onClick={() => {
        if (isSwitchingNetwork) return;
        if (isConnected && isWrongNetwork)
          useSwitchNetworkModal.getState().openModal();
        else useWalletModal.getState().openModal();
      }}
    >
      {isConnected ? (
        isWrongNetwork ? (
          <WrongNetworkText />
        ) : (
          account && (
            <Address
              fontSize={{
                base: '14px',
                lg: '24px',
              }}
              fontWeight={{
                base: 500,
              }}
              address={account}
              tailingChars={4}
              pt={{
                base: 1,
                lg: '10px',
              }}
            />
          )
        )
      ) : (
        <ConnectText />
      )}
    </Button>
  );
};
