import { useQuery } from '@tanstack/react-query';
import type { ErrorResponse } from '@ui/hooks/query/type';
import { useHandleError } from '@ui/hooks/useHandleError';
import { getApiUrl } from '@ui/utils';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';

import type { GetCommodityData } from '../types';

async function getCommodity({
  tgId,
  initData,
  handleError,
}: {
  tgId?: number;
  initData?: string;
  handleError: (error: ErrorResponse) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<GetCommodityData[]> {
  if (!tgId) {
    throw new Error('Invalid TG ID');
  }
  const res = await fetch(getApiUrl(`/user/${tgId}/commodities`), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'tg-init-data': initData ?? '',
    },
  });

  if (!res.ok) {
    const error = await res.json();
    handleError(error);
    return Promise.reject(error);
  }
  const response = await res.json();

  return response;
}

export const useGetCommodities = () => {
  const [initData, user] = useTgInfoStore((s) => [s.initData, s.user]);
  const handleError = useHandleError();

  return useQuery(
    ['user.commodities', user.id, initData],
    () => getCommodity({ tgId: user.id, initData, handleError }),
    { enabled: !!user.id && !!initData },
  );
};
