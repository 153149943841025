import { VaultCompleted } from '@ballz-app/icons';
import { currentWallet, useDesiredChain } from '@ballz-app/wallet';
import { Image } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import LoadingGif from '@public/images/staking/vault-loading.gif';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useBallzToast } from '@ui/hooks/useBallzToast';
import { getSDK } from '@ui/utils';
import { useState } from 'react';

import { getUseBallzBalanceQueryKey } from '../../../hooks/contracts/useBallzBalance';
import { getUseBallzVaultUserInfoQueryKey } from './useBallzVaultUserInfo';

const useBallzWithdraw = () => {
  const desiredChainId = useDesiredChain();
  const sdk = getSDK(desiredChainId);
  const [isLoading, setIsLoading] = useState(false);
  const account = currentWallet.useAccount();
  const provider = currentWallet.useProvider();
  const isWrongNetwork = currentWallet.useIsWrongNetwork();
  const queryClient = useQueryClient();
  const toast = useBallzToast();

  const data = useMutation(
    ['BallzVault.Withdraw'],
    async (stakeId: BigNumber) => {
      const signer = await provider?.getSigner();
      const ballzVaultContract = sdk.getBallzVault(signer);

      setIsLoading(true);
      try {
        if (account && !isWrongNetwork) {
          const ret = await ballzVaultContract.withdraw(stakeId.toString());

          toast({
            description: `Taking back your ballz...`,
            status: 'loading',
            icon: (
              <Image
                alt="loading"
                src={LoadingGif.src}
                width="24px"
                height="24px"
              />
            ),
            duration: null,
            hash: ret.hash,
          });
          await ret.wait();

          toast({
            description: `We cleared out the vault, peace out!`,
            status: 'success',
            icon: <VaultCompleted fontSize="24px" />,
          });
        }
      } catch (e) {
        toast({
          description: `Something went wrong, please try again!`,
          status: 'error',
          icon: <VaultCompleted fontSize="24px" />,
        });
        setIsLoading(false);
      }
    },
    {
      onError: () => {
        setIsLoading(false);
      },
      onSuccess: () => {
        setIsLoading(false);
        queryClient.invalidateQueries(
          getUseBallzVaultUserInfoQueryKey(account),
        );
        queryClient.invalidateQueries(getUseBallzBalanceQueryKey(account));
      },
    },
  );

  return {
    ...data,
    isLoading,
  };
};

export default useBallzWithdraw;
