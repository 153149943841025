import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const SvgEarn = (props) => (_jsxs("svg", { "data-name": "\\u5716\\u5C64_1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32", width: "1em", height: "1em", ...props, children: [_jsx("path", { d: "M14.62 26.6c-.45 0-.89-.02-1.34-.05-.57-.05-1.14-.14-1.7-.23l-.65-.1c-.1-.01-.19-.05-.26-.07a.575.575 0 0 1-.33-.28c-.06-.12-.11-.28-.06-.4.13-.33.12-.71.12-1.11V18.5c0-.27.21-.49.47-.5l.35-.02c.31-.02.63-.04.95-.04H15.53c.89 0 1.64.33 2.24.97.49.53.36 1.03.24 1.28-.21.44-.6.68-1.11.68h-2.81c-.08 0-.14.05-.15.12 0 .07.04.13.11.15l.08.5.02-.5H17.58c.22 0 .42-.03.57-.09.67-.25 1.17-.46 1.62-.68.39-.19.69-.54 1-.91l.29-.33c.17-.2.34-.39.52-.58.45-.49 1.05-.75 1.68-.75.69 0 1.33.32 1.74.89.58.8.58 1.84 0 2.55l-.43.53c-.73.9-1.48 1.83-2.38 2.62-1.42 1.24-3.15 1.95-5.13 2.12-.73.06-1.58.12-2.45.12Z", style: {
                fill: '#fff',
            } }), _jsx("path", { d: "M23.26 18.28c.51 0 1.01.23 1.34.68.42.57.47 1.39.02 1.94-.87 1.07-1.71 2.18-2.75 3.09-1.38 1.21-3.02 1.84-4.84 2-.8.07-1.6.12-2.41.12-.43 0-.86-.02-1.29-.05-.78-.07-1.55-.21-2.32-.33-.08-.01-.16-.05-.26-.08.19-.5.15-1.01.15-1.52V18.51c.41-.02.85-.05 1.28-.06h3.35c.74 0 1.37.27 1.88.81.2.21.29.45.16.73-.13.28-.36.39-.66.39H14.1c-.34 0-.61.24-.64.56-.04.32.18.62.5.7.07.02.15.02.22.02h3.41c.25 0 .51-.03.74-.12.56-.21 1.12-.43 1.66-.7.54-.27.91-.75 1.3-1.19.22-.25.43-.51.66-.75.37-.4.85-.59 1.32-.59m-.01-1.03c-.76 0-1.51.33-2.05.91-.18.2-.36.4-.53.6l-.15.17-.14.16c-.3.35-.55.64-.84.79-.32.16-.66.31-1.08.47.29-.62.16-1.28-.34-1.82a3.48 3.48 0 0 0-2.61-1.13H12.16c-.32 0-.64.02-.94.04l-.38.02c-.53.03-.95.47-.95 1V24.36c0 .36 0 .67-.08.92-.1.26-.09.55.03.8s.34.44.6.52l.05.02c.08.03.21.07.36.1.22.03.43.07.65.1.56.09 1.14.18 1.73.23.44.04.89.06 1.38.06.88 0 1.75-.06 2.49-.12 2.09-.17 3.92-.93 5.42-2.24.94-.82 1.71-1.77 2.45-2.69l.42-.52c.72-.88.72-2.18.01-3.16-.51-.7-1.29-1.1-2.14-1.1Z", style: {
                fill: '#3b254c',
            } }), _jsx("path", { d: "M10.89 22.16v2.77c0 .83-.54 1.36-1.37 1.37H8.39c-.77 0-1.32-.55-1.32-1.32v-5.65c0-.75.53-1.29 1.28-1.31h1.23c.78.01 1.3.55 1.31 1.33v2.83Z", style: {
                fill: '#47fce9',
                strokeMiterlimit: 10,
                stroke: '#3b254c',
            } }), _jsx("path", { d: "M12.9 6.23s.03-.03.04-.05c-1.34.63-2.43 1.86-2.83 3.56-.08.35-.13.72-.15 1.09-.09 2.73 1.54 4.15 3.06 4.74.52.2 1.03.31 1.45.33 2.64.15 5.6-1.89 5.68-4.32.07-2.05-.57-3.4-1.42-4.3-1.63-1.73-3.98-1.97-5.84-1.05Z", style: {
                fill: '#fff',
                fillRule: 'evenodd',
            } }), _jsx("path", { d: "M16.2 15.51c0 .18.08.34.22.45.06.04.12.08.19.1 1.76.57 4.14-.19 5.08-2.42.89-2.09-.11-4.18-1.37-5a.57.57 0 0 0-.67.03c-.19.16-.26.42-.17.65.96 2.44-.59 5.03-2.85 5.64a.95.95 0 0 0-.21.09c-.15.11-.23.28-.23.46Z", style: {
                fill: '#f8478b',
                strokeLinejoin: 'round',
                strokeWidth: '1.13px',
                stroke: '#3b254c',
            } }), _jsx("path", { d: "M14.48 15.9c-1.65-.09-4.6-1.41-4.47-5.05.01-.37.06-.73.15-1.09.94-4.04 5.8-5.42 8.59-2.47.85.9 1.49 2.25 1.42 4.3-.08 2.43-3.04 4.46-5.68 4.32Z", style: {
                fill: '#b7fef6',
            } }), _jsx("path", { d: "M18.31 7.62c-2-2.12-5.23-1.69-6.78.49 1.69-1.31 4.22-1.32 5.89.45.75.79 1.31 1.98 1.25 3.78-.02.61-.24 1.18-.61 1.7.87-.69 1.47-1.62 1.51-2.63.06-1.8-.5-2.98-1.25-3.78Z", style: {
                fill: '#e9fffc',
            } }), _jsx("path", { d: "M15.68 13.85c-1.48-.08-4.12-1.26-4-4.52.01-.33.06-.66.13-.98.2-.87.61-1.59 1.14-2.17-1.34.63-2.43 1.86-2.83 3.56-.08.35-.13.72-.15 1.09-.12 3.64 2.82 4.96 4.47 5.05 2.57.14 5.44-1.78 5.67-4.12-.95 1.29-2.76 2.18-4.43 2.08Z", style: {
                fill: '#47fce9',
            } }), _jsx("path", { d: "M14.48 15.9c-1.65-.09-4.6-1.41-4.47-5.05.01-.37.06-.73.15-1.09.94-4.04 5.8-5.42 8.59-2.47.85.9 1.49 2.25 1.42 4.3-.08 2.43-3.04 4.46-5.68 4.32Z", style: {
                strokeMiterlimit: 10,
                stroke: '#3b254c',
                fill: 'none',
            } })] }));
export default SvgEarn;
