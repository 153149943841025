import { Transaction } from '@ballz-app/icons';
import {
  HStack,
  Icon,
  Image,
  Text,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import First from '@public/images/first.svg';
import Second from '@public/images/second.svg';
import { useBallzToast } from '@ui/hooks/useBallzToast';
import { useCallback } from 'react';

import { StrokeText } from '../StrokeText';

export const Detail = ({ amount }: { amount: number }) => {
  const { onCopy } = useClipboard(process.env.EOA);
  const toast = useBallzToast();

  const copy = useCallback(() => {
    onCopy();
    toast({
      status: 'success',
      description: 'Copied to clipboard',
    });
  }, [onCopy, toast]);

  return (
    <VStack spacing={5} align="flex-start">
      <StrokeText
        fontSize="15px"
        strokeWidth="3px"
        strokeColor="#3B254C"
        color="brand.yellow"
      >
        what’s next?
      </StrokeText>
      <VStack>
        <HStack>
          <Image alt="first" src={First.src} />
          <Text fontSize={12} fontWeight={400} color="brand.purple">
            Transfer {amount} CRO to the following wallet within 5min with the
            wallet you’ve bound
          </Text>
        </HStack>
        <HStack
          fontWeight={400}
          borderRadius={4}
          borderWidth={1}
          borderColor="brand.primary"
          bg="#B7FEF6"
          p={3}
        >
          <Text fontSize={14} width="236px" color="brand.purple">
            {process.env.EOA}
          </Text>
          <Text fontSize={12} color="brand.pink" onClick={copy}>
            Copy
          </Text>
        </HStack>
        <HStack>
          <Image alt="second" src={Second.src} />
          <Text fontSize={12} fontWeight={400} color="brand.purple">
            Ballz will be transfer to your telegram account within 5 mins
          </Text>
        </HStack>
      </VStack>
      <HStack align="flex-start">
        <Icon as={Transaction} fontSize={20} />
        <Text fontSize={12} fontWeight={400} color="brand.purple">
          Please transfer only{' '}
          <Text as="span" color="brand.pink">
            {amount} CRO with the wallet you’ve bound
          </Text>
          . Or else you won’t be able to enjoy the discount rate, we’ll buy
          ballz with regular price CRO
        </Text>
      </HStack>
    </VStack>
  );
};
