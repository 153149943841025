import type { SVGProps } from 'react';

export enum PageName {
  About = 'about',
  Earn = 'earn',
}

export interface NavigatorItem {
  name: PageName;
  displayName: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  path: string;
}
