import { currentWallet, useDesiredChain } from '@ballz-app/wallet';
import { BigNumber } from '@ethersproject/bignumber';
import { useQuery } from '@tanstack/react-query';
import { BALLZ_DECIMALS } from '@ui/config/sc';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

export const getUseBallzBalanceQueryKey = (account: string | undefined) => [
  'Ballz.Balance',
  account,
];

export const useBallzBalance = () => {
  const desiredChainId = useDesiredChain();
  const account = currentWallet.useAccount();

  return useQuery(
    getUseBallzBalanceQueryKey(account),
    async () => {
      const sdk = getSDK(desiredChainId);
      const Ballz = sdk.getBallz();

      const balance = await Ballz.balanceOf(account ?? '');

      return {
        totalNumber: BigNumber.from(balance),
        totalAmount: new Fraction(balance).shr(BALLZ_DECIMALS),
      };
    },
    {
      enabled: !!account,
    },
  );
};
