import { BigNumber } from '@ethersproject/bignumber';
import type { QueryKey, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useBallzVaultContract } from '@ui/hooks/contracts/useBallzVaultContract';
import { expandDecimals } from '@ui/utils/formatNumber';

import type { VaultCardInfo } from '../types';
import type { BallzVaultUserInfo } from './useBallzVaultUserInfo';
import useBallzVaultUserInfo from './useBallzVaultUserInfo';

export const SECONDS_PER_YEAR = 31536000;
export const DIGITS = 4;

export const getUseBallzVaultInfoListQueryKey = (
  userInfoData: BallzVaultUserInfo | undefined,
): QueryKey => ['BallzVault.vaultInfoList', userInfoData];

export const lockPeriodMap = {
  '0': '3 months vault',
  '1': '6 months vault',
  '2': '12 months vault',
};
const useVaultInfoList: () => UseQueryResult<VaultCardInfo[]> = () => {
  const ballzVaultContract = useBallzVaultContract();
  const { data: userInfoData } = useBallzVaultUserInfo();

  return useQuery<VaultCardInfo[]>(
    getUseBallzVaultInfoListQueryKey(userInfoData),
    async () => {
      try {
        const poolCounts = await ballzVaultContract.poolLength();
        const rewardsPerSecond = await ballzVaultContract.rewardPerSecond();
        const rewardPerYear =
          BigNumber.from(rewardsPerSecond).mul(SECONDS_PER_YEAR);
        const rawPoolInfos = await Promise.all(
          [...Array(Number(poolCounts)).keys()].map((pid) =>
            ballzVaultContract.poolInfo(pid),
          ),
        );
        const totalWeightedStaked = rawPoolInfos.reduce(
          (acc, curr) =>
            acc.add(BigNumber.from(curr.totalAmount).mul(curr.multiplier)),
          BigNumber.from(0),
        );
        return rawPoolInfos.map<VaultCardInfo>(
          ({ multiplier, lockPeriod, totalAmount }, i) => {
            const totalAmountBn = BigNumber.from(totalAmount);
            const weightedStaked = BigNumber.from(totalAmount).mul(multiplier);

            let apr = 0;
            if (totalWeightedStaked.gt(0) && totalAmountBn.gt(0)) {
              const numerator = rewardPerYear
                .mul(weightedStaked)
                .mul(expandDecimals(DIGITS));

              const denominator = totalWeightedStaked.mul(totalAmountBn);

              apr = Number(numerator.div(denominator)) / 10 ** DIGITS;
            }

            return {
              pid: i,
              apr,
              totalAmount: BigNumber.from(totalAmount), // Convert bigint to BigNumber
              multiplier: Number(multiplier), // Convert bigint to number
              lockPeriod: Number(lockPeriod),
              lockPeriodTitle:
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                lockPeriodMap[i as unknown as keyof typeof lockPeriodMap],
              totalStaked:
                userInfoData?.poolTotalStakeMap[i] ?? BigNumber.from(0),
            };
          },
        );
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    {
      enabled: !!ballzVaultContract,
    },
  );
};

export default useVaultInfoList;
