export var PostHogEvents;
(function (PostHogEvents) {
    PostHogEvents["Test"] = "test";
    PostHogEvents["CheckIn"] = "check_in";
    PostHogEvents["OpenBuyModal"] = "open_buy_modal";
    PostHogEvents["Buy"] = "buy";
    PostHogEvents["Stake"] = "stake";
    PostHogEvents["Withdraw"] = "withdraw";
    PostHogEvents["Upgrade"] = "upgrade";
    PostHogEvents["ClaimAll"] = "claim_all";
})(PostHogEvents || (PostHogEvents = {}));
