import { Bn } from './bn';

/**
 * Enumeration for representing units in thousands (K), millions (M), and billions (B).
 *
 * @readonly
 * @enum {string}
 */
export enum Unit {
  K = 'K',
  M = 'M',
  B = 'B',
}

/**
 * Function to convert a numeric string into a simplified format using K, M, or B units.
 *
 * The function takes a numeric string as input and returns a tuple of the transformed value and its corresponding unit.
 *
 * @param {string} value - The numeric string to be converted.
 * @returns {[string, Unit?]} The transformed numeric string and its corresponding unit,
 * or undefined if the number is smaller than 1e3.
 */
export const getUnit = (value: string): [string, Unit?] => {
  const n = Bn(value);

  if (n.lt(1e3)) return [n.toFixed(), undefined];

  if (n.gte(1e3) && n.lt(1e6)) return [n.div(1e3).toFixed(), Unit.K];

  if (n.gte(1e6) && n.lt(1e9)) return [n.div(1e6).toFixed(), Unit.M];

  return [n.div(1e9).toFixed(), Unit.B];
};
