import {
  currentWallet,
  getAddEthereumChainParameter,
  useDesiredChain,
  walletConfigs,
} from '@ballz-app/wallet';
import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import CloseButton from '@public/images/close-button.png';
import type { FC } from 'react';
import { Fragment } from 'react';
import { create } from 'zustand';

import { StrokeText } from '../StrokeText';
import { ConnectedBody } from './ConnectedBody';
import { WalletCard } from './WalletCard';

interface UseWalletModal {
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
}

export const useWalletModal = create<UseWalletModal>((set) => ({
  isOpen: false,
  closeModal: () => {
    set({ isOpen: false });
  },
  openModal: () => {
    set({ isOpen: true });
  },
}));

export const WalletModal: FC<unknown> = () => {
  const isConnected = currentWallet.useIsConnected();
  const isOpen = useWalletModal((s) => s.isOpen);
  const closeModal = useWalletModal((s) => s.closeModal);
  const chainId = useDesiredChain();

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent
        borderRadius="15px"
        bg="#D1FFFA"
        box-shadow="5px 4px 0px 0px #F0DCFF"
      >
        <ModalHeader pt="30px" px="24px">
          <StrokeText
            color="brand.white"
            fontSize={25}
            lineHeight="23px"
            strokeWidth="6px"
            strokeColor="#3B254C"
          >
            {isConnected ? 'Your Wallet' : 'Connect Wallet'}
          </StrokeText>
          <Image
            cursor="pointer"
            position="absolute"
            src={CloseButton.src}
            alt="close modal"
            right="24px"
            top="20px"
            width="40px"
            height="40px"
            onClick={closeModal}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          {isConnected ? (
            <ConnectedBody />
          ) : (
            <Flex direction="column" gap={2}>
              {walletConfigs.map((v) => (
                <Fragment key={v.name}>
                  <WalletCard
                    onClick={() => {
                      closeModal();
                      currentWallet.connectAsCurrentWallet(
                        v.name,
                        getAddEthereumChainParameter(chainId),
                      );
                    }}
                    walletConfig={v}
                  />
                </Fragment>
              ))}
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
