import { VaultCompleted } from '@ballz-app/icons';
import { currentWallet, useDesiredChain } from '@ballz-app/wallet';
import { Image } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import { MaxUint256 } from '@ethersproject/constants';
import LoadingGif from '@public/images/staking/vault-loading.gif';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BALLZ_DECIMALS } from '@ui/config/sc';
import { useBallzToast } from '@ui/hooks/useBallzToast';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { useState } from 'react';

import useBallzVaultUserInfo, {
  getUseBallzVaultUserInfoQueryKey,
} from './useBallzVaultUserInfo';
import { useCurrentVault } from './useCurrentVault';
import { getUseBallzVaultInfoListQueryKey } from './useVaultInfoList';

const useBallzVaultDeposit = () => {
  const desiredChainId = useDesiredChain();
  const sdk = getSDK(desiredChainId);
  const [isLoading, setIsLoading] = useState(false);
  const account = currentWallet.useAccount();
  const { currentVault } = useCurrentVault();
  const provider = currentWallet.useProvider();
  const isWrongNetwork = currentWallet.useIsWrongNetwork();
  const queryClient = useQueryClient();
  const { data: userInfoData } = useBallzVaultUserInfo();
  const toast = useBallzToast();

  const data = useMutation(
    ['BallzVault.deposit'],
    async ({ amount }: { amount: string }) => {
      const signer = await provider?.getSigner();
      const ballzContract = sdk.getBallz(signer);
      const ballzVaultContract = sdk.getBallzVault(signer);
      setIsLoading(true);

      try {
        if (account && currentVault && !isWrongNetwork) {
          const inputTokenInt = new Fraction(amount).shl(
            BALLZ_DECIMALS,
          ).quotient;

          const allowance = await ballzContract.allowance(
            account,
            sdk.getContractAddress('BallzVault'),
          );

          const allowanceWithBigNumber = BigNumber.from(allowance);

          if (allowanceWithBigNumber.lt(inputTokenInt)) {
            const approveRet = await ballzContract.approve(
              sdk.getContractAddress('BallzVault'),
              MaxUint256.toString(),
            );

            toast({
              description: `Waiting for approval`,
              status: 'loading',
              icon: (
                <Image
                  alt="loading"
                  src={LoadingGif.src}
                  width="24px"
                  height="24px"
                />
              ),
              duration: null,
              hash: approveRet.hash,
            });

            await approveRet.wait();

            toast({
              description: `Green light on spending, we're good to go!`,
              status: 'success',
              icon: <VaultCompleted fontSize="24px" />,
            });
          }

          const tx = await ballzVaultContract.deposit(
            currentVault.pid,
            inputTokenInt,
          );
          toast({
            description: `Dropping your ballz into the vault...`,
            status: 'loading',
            icon: (
              <Image
                alt="loading"
                src={LoadingGif.src}
                width="24px"
                height="24px"
              />
            ),
            duration: null,
            hash: tx.hash,
          });

          const receipt = await tx.wait();

          toast({
            description: `Great, we nailed it!`,
            status: 'success',
            icon: <VaultCompleted fontSize="24px" />,
          });

          return { receipt };
        }
      } catch (error) {
        toast({
          description: `Something went wrong, please try again!`,
          status: 'error',
          icon: <VaultCompleted fontSize="24px" />,
        });
        setIsLoading(false);
      }
    },
    {
      onError: () => {
        setIsLoading(false);
      },
      onSuccess: () => {
        setIsLoading(false);
        queryClient.invalidateQueries(
          getUseBallzVaultInfoListQueryKey(userInfoData),
        );
        queryClient.invalidateQueries(
          getUseBallzVaultUserInfoQueryKey(account),
        );
      },
    },
  );

  return {
    ...data,
    isLoading,
  };
};

export default useBallzVaultDeposit;
