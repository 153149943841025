import { useEffect, useState } from 'react';

export const useCountDown = (timestamp: number) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (timestamp === 0) {
      return;
    }
    const interval = setInterval(() => {
      const diffTime = timestamp - Date.now();

      setTimer(Math.max(0, diffTime));
    }, 1000);

    return () => clearInterval(interval);
  }, [timer, timestamp]);

  return timer;
};
