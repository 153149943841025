import { useQuery } from '@tanstack/react-query';
import { getBallzAddressByEnv } from '@ui/utils/getBallzAddressByEnv';

import { VVS_PRICE_URL } from './usePriceData';

interface VvsPrice {
  updated_at: number;
  data: {
    name: string;
    symbol: string;
    price: string;
    price_CRO: string;
  };
}
export const getPriceFromVVS = (tokenAddress: string) =>
  `${VVS_PRICE_URL}/${tokenAddress}`;

export const useBallzPrice = () => {
  const ballzAddress = getBallzAddressByEnv();

  return useQuery<number>({
    queryKey: ['useUsdPrice', ballzAddress],
    queryFn: async () => {
      try {
        // only get the price from prod
        const response = await fetch(
          getPriceFromVVS('0xB157c8560984EBa102334c5Eb4F0416952747a6e'),
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: VvsPrice = await response.json();
        return Number(data.data.price || 0);
      } catch (_) {
        return 0;
      }
    },
  });
};
