import { BindModal } from './BindModal';
import { useBindModal } from './BindModal/useBindModal';
import { BuyModal } from './BuyModal';
import { useBuyModal } from './BuyModal/hooks/useBuyModal';
import { CreateOrderModal } from './CreateOrder';
import { useCreateOrderModal } from './CreateOrder/useCreateOrderModal';
import { DevUtils } from './DevUtils';
import { LazyComponent } from './LazyComponent';
import { OnboardingModal } from './OnboardingModal';
import { useOnboardingModal } from './OnboardingModal/useOnboardingModal';
import { OrderDetailModal } from './OrderDetailModal';
import { useOrderDetailModal } from './OrderDetailModal/useOrderDetailModal';
import { RequireWallet } from './RequireWallet';
import { StakeDrawer } from './Stake/StakeDrawer';
import { useStakeDrawer } from './Stake/StakeDrawer/useStakeDrawer';
import { VaultDrawer } from './Stake/VaultDrawer';
import { useVaultDrawer } from './Stake/VaultDrawer/useVaultDrawer';
import {
  SwitchNetworkModal,
  useSwitchNetworkModal,
} from './SwitchNetworkModal';
import { useWalletModal, WalletModal } from './Wallet';

export const GlobalComponents = () => {
  const isOpenWalletModalOpen = useWalletModal((s) => s.isOpen);
  const isOnboardingModalOpen = useOnboardingModal((s) => s.isOpen);
  const isBuyModalOpen = useBuyModal((s) => s.isOpen);
  const isBindModalOpen = useBindModal((s) => s.isOpen);
  const isStakeDrawerOpen = useStakeDrawer((s) => s.isOpen);
  const isVaultDrawerOpen = useVaultDrawer((s) => s.isOpen);
  const isOpenSwitchNetworkModal = useSwitchNetworkModal((s) => s.isOpen);
  const isOpenCreateOrderModal = useCreateOrderModal((s) => s.isOpen);
  const isOpenOrderDetailModal = useOrderDetailModal((s) => s.isOpen);

  return (
    <>
      <DevUtils />
      <RequireWallet isOpen={isStakeDrawerOpen}>
        <StakeDrawer />
      </RequireWallet>
      <RequireWallet isOpen={isVaultDrawerOpen}>
        <VaultDrawer />
      </RequireWallet>
      <LazyComponent isOpen={isOpenWalletModalOpen}>
        <WalletModal />
      </LazyComponent>
      <LazyComponent isOpen={isOpenSwitchNetworkModal}>
        <SwitchNetworkModal />
      </LazyComponent>
      <LazyComponent isOpen={isOnboardingModalOpen}>
        <OnboardingModal />
      </LazyComponent>
      <LazyComponent isOpen={isBuyModalOpen}>
        <BuyModal />
      </LazyComponent>
      <LazyComponent isOpen={isOpenCreateOrderModal}>
        <CreateOrderModal />
      </LazyComponent>
      <LazyComponent isOpen={isOpenOrderDetailModal}>
        <OrderDetailModal />
      </LazyComponent>
      <LazyComponent isOpen={isBindModalOpen}>
        <BindModal />
      </LazyComponent>
    </>
  );
};
