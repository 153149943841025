import { Image } from '@chakra-ui/react';
import CloseButton from '@public/images/close-button.png';
import { StrokeText } from '@ui/components/StrokeText';
import { useCurrentVault } from '@ui/views/Earn/hooks/useCurrentVault';

import { useVaultDrawer } from './useVaultDrawer';

export const VaultHeader = () => {
  const { currentVault } = useCurrentVault();
  const { onClose } = useVaultDrawer((s) => ({
    onClose: s.closeDrawer,
  }));

  return (
    <>
      <StrokeText
        color="brand.purple"
        fontSize={{ base: 25, lg: 40 }}
        lineHeight="23px"
        strokeWidth="6px"
        strokeColor="#47FCE9"
      >
        {currentVault?.lockPeriodTitle} STAKING
      </StrokeText>
      <Image
        cursor="pointer"
        position="absolute"
        src={CloseButton.src}
        alt="close modal"
        right={{ base: '16px', lg: '50px' }}
        top={{ base: '12px', lg: '20px' }}
        width="40px"
        height="40px"
        onClick={onClose}
      />
    </>
  );
};
