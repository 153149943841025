import {
  Button,
  HStack,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import CloseButton from '@public/images/close-button.png';
import { useBallzToast } from '@ui/hooks/useBallzToast';
import { useCallback } from 'react';

import { DiscountType } from '../BuyModal/types';
import { OrderDetail } from '../OrderDetail';
import { useOrderDetailModal } from '../OrderDetailModal/useOrderDetailModal';
import { StrokeText } from '../StrokeText';
import { Detail } from './Detail';

export const OrderDetailModal = () => {
  const { isOpen, showToast, closeModal } = useOrderDetailModal((s) => ({
    isOpen: s.isOpen,
    showToast: s.showToast,
    closeModal: s.closeModal,
  }));
  const orderItem = useOrderDetailModal((s) => s.orderItem);
  const toast = useBallzToast();

  const onClose = useCallback(() => {
    closeModal();
    if (showToast) {
      toast({
        status: 'success',
        description:
          'Counting down 5 mins for you to complete your transaction and score some ballz!',
      });
    }
  }, [closeModal, showToast, toast]);

  if (!orderItem) {
    return null;
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} closeOnOverlayClick={false}>
      <ModalOverlay zIndex={10001} />
      <ModalContent
        w="339px"
        borderRadius="20px"
        bg="#D1FFFA"
        boxShadow="5px 4px 0px 0px #F0DCFF"
        containerProps={{
          zIndex: 10001,
        }}
        // reset scroll behavior
        // https://github.com/chakra-ui/chakra-ui/issues/7588#issuecomment-2478554865
        onWheel={(e) => {
          e.stopPropagation();
        }}
        onTouchMove={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onScroll={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        overflow="auto"
      >
        <Image
          cursor="pointer"
          position="absolute"
          src={CloseButton.src}
          alt="close modal"
          right="13px"
          top="20px"
          width="40px"
          height="40px"
          onClick={onClose}
        />
        <VStack
          bg="#D1FFFA"
          borderRadius="20px"
          py="30px"
          px={6}
          spacing={4.5}
          fontSize={16}
          lineHeight="89%"
          fontWeight={500}
        >
          <StrokeText
            color="brand.white"
            fontSize={25}
            lineHeight="23px"
            strokeWidth="6px"
            strokeColor="#3B254C"
            width="full"
            textAlign="left"
          >
            {orderItem.discountType === DiscountType.NORMAL
              ? 'Create an order'
              : 'Order created'}
          </StrokeText>
          <VStack spacing={4.5}>
            <OrderDetail commodity={orderItem} isShowExpired />
            <Detail amount={orderItem.discountValue} />
            <VStack spacing={4}>
              <Button
                border="3px solid #3B254C"
                borderRadius={16}
                boxShadow="4px 4px 0px 0px #FF7BAE"
                bg="brand.pink"
                position="relative"
                onClick={onClose}
                pt={4}
                pb={2.5}
                w="full"
                size="lg"
                _hover={{
                  boxShadow: 'unset',
                }}
                _active={{
                  boxShadow: 'unset',
                }}
              >
                <HStack align="baseline">
                  <StrokeText
                    color="brand.primary"
                    fontSize={21}
                    lineHeight="21px"
                    strokeWidth="6px"
                    strokeColor="#3B254C"
                  >
                    Done
                  </StrokeText>
                </HStack>
              </Button>
              <Text
                textAlign="center"
                width="269px"
                fontSize={12}
                color="brand.pink"
                fontWeight={400}
              >
                To ensure your tokens are recognized, please send them only from
                your bound wallet address
              </Text>
            </VStack>
          </VStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
