import type { BoxProps } from '@chakra-ui/react';
import { Box, Button, HStack } from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { NavigatorList } from './constants';

export const PageNavigatorMobile = (props: BoxProps) => {
  const pathname = usePathname();
  const router = useRouter();

  return (
    <>
      <Box
        width="100vw"
        position="fixed"
        bottom="20px"
        zIndex="20"
        left={0}
        px="10px"
        display={{
          base: 'block',
          lg: 'none',
        }}
        {...props}
      >
        <HStack
          borderRadius="16px"
          bg="#3B254C"
          width="100%"
          height="64px"
          p="10px"
        >
          {NavigatorList.map((item) => (
            <Button
              flex="1"
              leftIcon={<item.icon height="30px" width="30px" />}
              bg={pathname === item.path ? '#47FCE9' : '#3B254C'}
              color={pathname === item.path ? 'black' : 'white'}
              onClick={() => {
                router.push(item.path);
              }}
              key={item.name}
              fontSize="16px"
              fontWeight="500"
            >
              {item.displayName}
            </Button>
          ))}
        </HStack>
      </Box>
    </>
  );
};
