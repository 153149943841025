import { ChainId } from '../types';
const ADDRESS_BY_CHAIN = {
    [ChainId.CRONOS_MAINNET]: {
        WCRO: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
        CronosId: '0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5',
        BallzVault: '0xf1B2F7679d0232cDaABaaf90127add850F1B96EE',
        Ballz: '0xB157c8560984EBa102334c5Eb4F0416952747a6e',
    },
    [ChainId.CRONOS_TESTNET]: {
        WCRO: '0x6a3173618859C7cd40fAF6921b5E9eB6A76f1fD4',
        CronosId: '0x16a23bFBcE9c53998c90201629E4cDB40B81B127',
        BallzVault: '0x8722e9F5047a1bD277E8Cf072Ab291a11eDe0934',
        Ballz: '0x48512ee26Ac69716de17c1472199fae7e16cD15f',
    },
};
export const getContractAddress = (chainId, contractName) => {
    return ADDRESS_BY_CHAIN[chainId][contractName];
};
export const getEnsAddress = (chainId) => {
    switch (chainId) {
        case ChainId.CRONOS_MAINNET:
        case ChainId.CRONOS_TESTNET:
            return getContractAddress(chainId, 'CronosId');
        default:
            throw new Error('unreachable');
    }
};
