import { useQuery } from '@tanstack/react-query';

import { VVS_PRICE_URL } from './usePriceData';

interface VvsPrice {
  updated_at: number;
  data: {
    name: string;
    symbol: string;
    price: string;
    price_CRO: string;
  };
}
export const getPriceFromVVS = (tokenAddress: string) =>
  `${VVS_PRICE_URL}/${tokenAddress}`;

export const useCroPrice = () => {
  return useQuery<number>({
    queryKey: ['useCroUsdPrice'],
    queryFn: async () => {
      try {
        // only get the price from prod
        const response = await fetch(
          getPriceFromVVS('0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23'),
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: VvsPrice = await response.json();
        return Number(data.data.price || 0);
      } catch (_) {
        return 0;
      }
    },
  });
};
