import BigNumberJs from 'bignumber.js';

export type BnConfig = BigNumberJs.Config;
export type BnFormat = BigNumberJs.Format;

const DECIMAL_PLACES = 30;

// https://mikemcl.github.io/bignumber.js/#format
export const DEFAULT_FORMAT: BnFormat = {
  prefix: '',
  groupSize: 3,
  secondaryGroupSize: 0,
  groupSeparator: ',',
  decimalSeparator: '.',
  fractionGroupSize: 0,
  fractionGroupSeparator: '\xA0', // non-breaking space
  suffix: '',
};

// https://mikemcl.github.io/bignumber.js/#config
BigNumberJs.config({
  DECIMAL_PLACES,
  ROUNDING_MODE: BigNumberJs.ROUND_HALF_UP,
  EXPONENTIAL_AT: [-DECIMAL_PLACES * 2, DECIMAL_PLACES * 2],
  RANGE: [-1e9, 1e9],
  CRYPTO: false,
  MODULO_MODE: BigNumberJs.ROUND_DOWN,
  POW_PRECISION: 0,
  FORMAT: DEFAULT_FORMAT,
  ALPHABET: '0123456789abcdefghijklmnopqrstuvwxyz',
});

// The BigNumberJs constructor
export const Bn = BigNumberJs;
