import { currentWallet } from '@ballz-app/wallet';

import { LazyComponent } from './LazyComponent';

interface RequireWalletProps {
  children: React.ReactNode;
  isOpen: boolean;
}

/**
 * Only render child component when wallet is connected to the supported chain.
 *
 * We also want the child component to be lazy rendered
 */
export const RequireWallet: React.FC<RequireWalletProps> = ({
  children,
  isOpen,
}) => {
  const isConnected = currentWallet.useIsConnectedToSupportedChain();

  return (
    <>
      {isConnected && <LazyComponent isOpen={isOpen}>{children}</LazyComponent>}
    </>
  );
};
