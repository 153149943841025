import { useDesiredChain } from '@ballz-app/wallet';
import { getSDK } from '@ui/utils';
import { useMemo } from 'react';

export const useBallzVaultContract = () => {
  const desiredChainId = useDesiredChain();

  return useMemo(() => {
    const sdk = getSDK(desiredChainId);
    const ballzVaultContract = sdk.getBallzVault();
    return ballzVaultContract;
  }, [desiredChainId]);
};
