import { VStack } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';

import { useCurrentPurchaseItem } from '../hooks/useCurrentPurchaseItem';
import { useGetCommoditiesForChain } from '../hooks/useGetCommoditiesForChain';
import type { PurchaseType } from '../hooks/usePurchaseType';
import { useRefetchInterval } from '../hooks/useRefetchInterval';
import { SectionContainer, SectionType } from '../SectionContainer';
import { SellLoading } from '../SellLoading';
import { formatCommoditiesForChain } from '../utils';
import { Disclaimer } from '../WithStar/Disclaimer';
import { CountDown } from './CountDown';
import { TokenCommodity } from './TokenCommodity';

export interface WithTokenProps {
  purchaseType: PurchaseType.CRONOS | PurchaseType.CRONOSZK;
}
export const WithToken = ({ purchaseType }: WithTokenProps) => {
  const {
    data: rawCommodities,
    isLoading,
    refetch,
  } = useGetCommoditiesForChain(purchaseType);
  const [purchaseItem, setPurchaseItem, updatePurchaseItem] =
    useCurrentPurchaseItem((s) => [
      s.purchaseItem,
      s.setPurchaseItem,
      s.updatePurchaseItem,
    ]);
  const { resetAll } = useRefetchInterval();
  const [normalCommodities, epicCommodities, orders] = useMemo(() => {
    return formatCommoditiesForChain(rawCommodities);
  }, [rawCommodities]);

  useEffect(() => {
    const targetPurchaseItem = epicCommodities.find(
      (item) => item.key === purchaseItem?.key,
    );
    targetPurchaseItem && updatePurchaseItem(targetPurchaseItem);
  }, [epicCommodities, purchaseItem, setPurchaseItem, updatePurchaseItem]);

  useEffect(() => {
    return () => resetAll();
  }, [resetAll]);

  return (
    <VStack flex="1" height="100%" overflow="scroll" width="100%" spacing={0}>
      {isLoading ? (
        <SellLoading flex="1" width="100%" />
      ) : (
        <>
          <SectionContainer
            sectionType={SectionType.Epic}
            commodities={epicCommodities}
            CommodityDetail={TokenCommodity}
            orders={orders}
          >
            <CountDown refresh={refetch} />
          </SectionContainer>

          {/* regular */}
          <SectionContainer
            sectionType={SectionType.Regular}
            commodities={normalCommodities}
            CommodityDetail={TokenCommodity}
            pt="20px"
          >
            <CountDown refresh={refetch} />
          </SectionContainer>
        </>
      )}

      <Disclaimer />
    </VStack>
  );
};
