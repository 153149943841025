import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from 'react';
const SvgVaultCompleted = function (props) {
    const __useId = useId();
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", width: "1em", height: "1em", ...props, children: [_jsx("defs", { children: _jsx("style", { children: '.vault-completed_svg__cls-2{fill-rule:evenodd;fill:#fff}.vault-completed_svg__cls-4{fill:#f2acc9}.vault-completed_svg__cls-5{fill:#3b254c}.vault-completed_svg__cls-7{fill:#3f244e}' }) }), _jsxs("g", { id: `${'vault-completed_svg___\u5716\u5C64_1'}__${__useId}`, "data-name": "\\u5716\\u5C64_1", children: [_jsx("path", { className: "vault-completed_svg__cls-2", d: "M14.2 6.9c-1.3.6-2.4 1.9-2.8 3.6 0 .4-.1.7-.1 1.1 0 2.7 1.5 4.2 3.1 4.8.5.2 1 .3 1.5.3 2.7.1 5.6-1.9 5.7-4.3 0-2.1-.6-3.4-1.4-4.3-1.6-1.7-4-2-5.9-1.1Z" }), _jsx("path", { className: "vault-completed_svg__cls-4", d: "M15.8 16.6c-1.7 0-4.6-1.4-4.5-5.1 0-.4 0-.7.1-1.1 1-4.1 5.8-5.4 8.6-2.5.9.9 1.5 2.3 1.4 4.3 0 2.4-3.1 4.5-5.7 4.3h.1Z" }), _jsx("path", { className: "vault-completed_svg__cls-4", d: "M19.6 8.3c-2-2.1-5.3-1.7-6.8.5 1.7-1.3 4.2-1.3 5.9.4.8.8 1.3 2 1.3 3.8s-.2 1.2-.6 1.7c.9-.7 1.5-1.6 1.5-2.6 0-1.8-.5-3-1.3-3.8Z" }), _jsx("path", { d: "M17 14.5c-1.5 0-4.1-1.3-4-4.6 0-.3 0-.7.1-1 .2-.9.6-1.6 1.1-2.2-1.3.6-2.4 1.9-2.8 3.6 0 .4-.1.7-.1 1.1-.1 3.7 2.8 5 4.5 5.1 2.6.1 5.5-1.8 5.7-4.1-1 1.3-2.8 2.2-4.5 2.1Z", style: {
                            fill: '#da5286',
                        } }), _jsx("path", { className: "vault-completed_svg__cls-5", d: "M16 17h-.3c-2-.1-5-1.7-4.9-5.5 0-.4 0-.8.2-1.2.5-2.1 2-3.6 4-4.2 1.9-.5 3.9 0 5.3 1.5 1.1 1.1 1.6 2.7 1.5 4.6 0 1.1-.6 2.1-1.5 3-1.2 1.1-2.8 1.7-4.3 1.7Zm-.2-.8c1.4 0 2.9-.5 4-1.5.8-.7 1.3-1.6 1.3-2.5 0-1.7-.4-3.1-1.3-4.1-1.2-1.3-2.9-1.8-4.6-1.3-1.7.5-3 1.8-3.4 3.6 0 .3-.1.7-.1 1-.1 3.6 3 4.6 4.1 4.7Z" }), _jsx("path", { className: "vault-completed_svg__cls-2", d: "M8.7 4.8C7.1 5.6 5.8 7 5.3 9.1c0 .4-.2.9-.2 1.3 0 3.3 1.8 5 3.7 5.7.6.2 1.2.4 1.7.4 3.2.2 6.7-2.3 6.8-5.2 0-2.4-.7-4.1-1.7-5.1-2-2.1-4.8-2.4-7-1.3h.1Z" }), _jsx("path", { d: "M10.6 16.4c-2-.1-5.5-1.7-5.3-6 0-.4 0-.9.2-1.3 1.1-4.8 6.9-6.5 10.3-3 1 1.1 1.8 2.7 1.7 5.1 0 2.9-3.6 5.3-6.8 5.2Z", style: {
                            fill: '#b7fef6',
                        } }), _jsx("path", { d: "M15.1 6.5c-2.4-2.5-6.3-2-8.1.6 2-1.6 5-1.6 7 .5.9.9 1.6 2.4 1.5 4.5 0 .7-.3 1.4-.7 2 1-.8 1.8-1.9 1.8-3.1 0-2.1-.6-3.6-1.5-4.5Z", style: {
                            fill: '#e9fffc',
                        } }), _jsx("path", { d: "M12 13.9c-1.8 0-4.9-1.5-4.8-5.4 0-.4 0-.8.2-1.2.2-1 .7-1.9 1.4-2.6-1.6.8-2.9 2.2-3.4 4.3 0 .4-.2.9-.2 1.3-.2 4.4 3.4 5.9 5.3 6 3.1.2 6.5-2.1 6.8-4.9-1.1 1.5-3.3 2.6-5.3 2.5Z", style: {
                            fill: '#47fce9',
                        } }), _jsx("path", { className: "vault-completed_svg__cls-5", d: "M10.9 16.7h-.4c-2.4-.1-5.9-2-5.7-6.4 0-.5 0-.9.2-1.4.6-2.4 2.3-4.3 4.7-4.9 2.3-.6 4.6 0 6.2 1.8 1.3 1.3 1.9 3.2 1.8 5.4 0 1.3-.7 2.5-1.8 3.5-1.4 1.2-3.3 2-5 2Zm.4-12.2c-.5 0-1 0-1.5.2-2.1.6-3.6 2.2-4.1 4.4 0 .4 0 .8-.2 1.2-.2 4.4 3.6 5.6 5 5.7 1.7 0 3.5-.6 4.8-1.8 1-.9 1.5-2 1.6-3 0-2-.5-3.7-1.6-4.9s-2.5-1.8-4-1.8Z" }), _jsx("path", { d: "M10.5 22.4H9.1c-.6 0-1.2-.1-1.8-.2h-.7c0-.1-.2-.1-.3-.2-.1 0-.3-.2-.3-.3 0-.1-.1-.3 0-.4.1-.4.1-.8.1-1.2v-6.2c0-.3.2-.5.5-.5H11.5c.9 0 1.7.3 2.3 1 .5.5.4 1.1.3 1.3-.2.5-.6.7-1.1.7h-3s-.2 0-.2.1c0 0 0 .2.1.2h4.3c.7-.3 1.2-.5 1.7-.7.4-.2.7-.6 1.1-1l.3-.4c.2-.2.4-.4.5-.6.5-.5 1.1-.8 1.8-.8s1.4.3 1.8.9c.6.8.6 1.9 0 2.6l-.5.6c-.8.9-1.5 1.9-2.5 2.8-1.5 1.3-3.3 2-5.4 2.2-.8 0-1.7.1-2.6.1Z", style: {
                            fill: '#fff',
                        } }), _jsx("path", { className: "vault-completed_svg__cls-7", d: "M19.6 13.6c.5 0 1.1.2 1.4.7.4.6.5 1.5 0 2-.9 1.1-1.8 2.3-2.9 3.2-1.5 1.3-3.2 1.9-5.1 2.1-.8 0-1.7.1-2.5.1H9.1c-.8 0-1.6-.2-2.4-.3h-.3c.2-.5.2-1.1.2-1.6v-6h4.8c.8 0 1.4.3 2 .9.2.2.3.5.2.8-.1.3-.4.4-.7.4h-3c-.4 0-.6.3-.7.6 0 .3.2.7.5.7h3.8c.3 0 .5 0 .8-.1.6-.2 1.2-.5 1.7-.7.6-.3 1-.8 1.4-1.3.2-.3.5-.5.7-.8.4-.4.9-.6 1.4-.6m.1-1.1c-.8 0-1.6.3-2.1.9l-.6.6-.2.2v.2c-.5.4-.7.7-1 .8-.4.2-.8.4-1.3.5v-.1c.3-.6.2-1.3-.3-1.9-.7-.8-1.6-1.2-2.7-1.2h-5c-.5 0-1 .5-1 1v7.2c-.1.3 0 .5 0 .8.1.2.3.4.6.5 0 0 .2 0 .4.1.2 0 .5 0 .7.1.6 0 1.2.2 1.8.2h1.4c.9 0 1.8 0 2.6-.1 2.2-.2 4.1-1 5.7-2.3 1-.9 1.8-1.9 2.6-2.8l.4-.5c.7-.9.7-2.2 0-3.3-.5-.7-1.3-1.1-2.2-1.1Z" }), _jsx("path", { d: "M6.6 17.7v2.9c0 .9-.6 1.4-1.4 1.4H4c-.8 0-1.4-.6-1.4-1.4v-5.9c0-.8.6-1.4 1.3-1.4h1.3c.8 0 1.4.6 1.4 1.4v3Z", style: {
                            fill: '#00ffe8',
                            stroke: '#3f244e',
                            strokeMiterlimit: 10,
                        } }), _jsx("path", { className: "vault-completed_svg__cls-7", d: "M16.4 4.6c.1 0 .2 0 .3.1.2.2.2.5 0 .7l-4.8 6c0 .1-.2.2-.4.2s-.3-.1-.4-.3l-2-3.9c-.1-.2 0-.5.2-.7h.2c.2 0 .4 0 .4.3l1.6 3.2 4.3-5.4c0-.1.2-.2.4-.2m.2-1c-.5 0-.9.2-1.2.6l-3.4 4.2-.9-1.8c-.3-.5-.8-.8-1.3-.8s-.5 0-.7.2c-.4.2-.6.5-.7.9-.1.4 0 .8 0 1.1l2 3.9c.2.5.7.8 1.2.8h.2c.5 0 .9-.2 1.2-.6l4.8-6c.3-.3.4-.7.3-1.1 0-.4-.2-.8-.5-1-.3-.2-.6-.3-.9-.3Z" }), _jsx("path", { d: "M11.6 11.6c-.2 0-.3-.1-.4-.3l-2-3.9c-.1-.2 0-.5.2-.7.2-.1.5 0 .7.2l1.6 3.2L16 4.7c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-4.8 6c0 .1-.2.2-.4.2Z", style: {
                            fill: '#a0fff5',
                        } })] })] }));
};
export default SvgVaultCompleted;
