import { resetWhenRouterChange_raw } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

export enum PurchaseType {
  CRONOS = 'CRONOS',
  CRONOSZK = 'CRONOSZK',
  STAR = 'STAR',
}
interface usePurchaseTypeStore {
  purchaseType: PurchaseType;
  setPurchaseType: (purchaseType: PurchaseType) => void;
}

export const usePurchaseType = create<usePurchaseTypeStore>(
  resetWhenRouterChange_raw((set) => ({
    purchaseType: PurchaseType.CRONOS,
    setPurchaseType: (purchaseType: PurchaseType) => {
      set({
        purchaseType,
      });
    },
  })),
);
