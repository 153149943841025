import { Unbind } from '@ballz-app/icons';
import {
  HStack,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import CloseButton from '@public/images/close-button.png';
import { StrokeText } from '@ui/components/StrokeText';

import { AddressInput } from './AddressInput';
import { useBindModal } from './useBindModal';

export const BindModal = () => {
  const [isOpen, showWarning, closeModal] = useBindModal((s) => [
    s.isOpen,
    s.showWarning,
    s.closeModal,
  ]);

  return (
    <Modal
      scrollBehavior="inside"
      onClose={closeModal}
      isOpen={isOpen}
      closeOnOverlayClick={false}
    >
      <ModalOverlay background="rgba(0, 0, 0, 0.85)" />
      <ModalContent
        w="339px"
        borderRadius="20px"
        bg="#D1FFFA"
        boxShadow="5px 4px 0px 0px #F0DCFF"
        containerProps={{
          zIndex: 10001,
        }}
      >
        <Image
          cursor="pointer"
          position="absolute"
          src={CloseButton.src}
          alt="close modal"
          right="13px"
          top="20px"
          width="40px"
          height="40px"
          onClick={closeModal}
        />
        <VStack
          bg="#D1FFFA"
          borderRadius="20px"
          padding="30px 0px"
          spacing={6}
          fontSize={16}
          lineHeight="89%"
          fontWeight={500}
          px={6}
        >
          <StrokeText
            color="brand.white"
            fontSize={25}
            lineHeight="23px"
            strokeWidth="6px"
            strokeColor="#3B254C"
            width="full"
            textAlign="left"
          >
            Bind Wallet Now
          </StrokeText>
          {showWarning && (
            <HStack
              spacing={4}
              boxShadow="4px 4px 0px 0px #FFF"
              px={4}
              py={3}
              borderRadius={16}
              bg="bg.pink"
              align="flex-start"
            >
              <Icon as={Unbind} width="20px" height="20px" />
              <Text
                fontSize={14}
                fontWeight={400}
                lineHeight="12px"
                color="brand.purple"
              >
                No wallet no ballz! Bind your wallet first before creating an
                order.
              </Text>
            </HStack>
          )}
          <AddressInput onClose={closeModal} />
        </VStack>
      </ModalContent>
    </Modal>
  );
};
