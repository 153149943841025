import { PostHogEvents, postHogProxy } from '@ballz-app/analytics';
import { fontFamily } from '@ballz-app/chakra';
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import headerBg from '@public/images/staking/header-bg.png';
import { StrokeText } from '@ui/components/StrokeText';
import { convertBnToNumber } from '@ui/utils/formatNumber';
import { formatNumber } from '@ui/utils/formatter';
import { BallzAmountWithPrice } from '@ui/views/Earn/BallzAmountWithPrice';
import useBallzVaultUserInfo from '@ui/views/Earn/hooks/useBallzVaultUserInfo';
import useBallzWithdraw from '@ui/views/Earn/hooks/useBallzWithdraw';
import { useCurrentVault } from '@ui/views/Earn/hooks/useCurrentVault';
import type { Stake } from '@ui/views/Earn/types';
import { useMemo } from 'react';

import { useStakeDrawer } from '../StakeDrawer/useStakeDrawer';
import { UnlockedTime } from './UnlockedTime';
import { useVaultDrawer } from './useVaultDrawer';
import { VaultHeader } from './VaultHeader';

export const VaultDrawer = () => {
  const { isOpen, onClose } = useVaultDrawer((s) => ({
    isOpen: s.isOpen,
    onClose: s.closeDrawer,
  }));
  const { currentVault } = useCurrentVault();
  const { data } = useBallzVaultUserInfo();
  const { isLoading: isWithdrawLoading, mutateAsync: withdraw } =
    useBallzWithdraw();

  const stakes = useMemo<Stake[]>(() => {
    if (!currentVault || !data?.poolStakeMap) return [];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return data.poolStakeMap[currentVault.pid] ?? [];
  }, [currentVault, data]);

  const handleWithdraw = async (stake: Stake) => {
    stake.isUnlocked &&
      withdraw(stake.stakeId, {
        onSuccess: () => {
          if (!currentVault) return;
          postHogProxy.captureWithoutFeature({
            event: PostHogEvents.Withdraw,
            capture: {
              stakeId: stake.stakeId.toString(),
              vault: stake.poolId.toString(),
              amount: convertBnToNumber(stake.amount),
            },
          });
        },
      });
  };
  return (
    <Drawer
      size={{ base: 'full', lg: 'lg' }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent bg="#B7FEF6">
        <DrawerHeader
          pt={{ base: '24px', lg: '32px' }}
          pb={{ base: '18px', lg: '26px' }}
          px={{ base: '16px', lg: '54px' }}
          bg={`url(${headerBg.src})`}
        >
          <VaultHeader />
        </DrawerHeader>

        <DrawerBody p={0}>
          <VStack align="flex-start" py={6} px={4} spacing={6}>
            <VStack
              border="1px solid #3B254C"
              borderRadius={6}
              boxShadow="2.522px 2.522px 0px 0px #3B254C"
              flex={1}
              overflow="hidden"
              bg="#DEFFFC"
              width="full"
            >
              <Text
                height={{ base: '28px', lg: '52px' }}
                lineHeight={{ base: '28px', lg: '52px' }}
                color="#008B7D"
                fontFamily={fontFamily.luckiestGuy}
                fontSize={{ base: 16, lg: 22 }}
                bg="brand.primary"
                width="full"
                px={2}
              >
                {currentVault?.lockPeriodTitle} Vault
              </Text>
              <HStack
                px={4}
                py={3}
                align="flex-start"
                justify="space-between"
                spacing={4}
                width="full"
              >
                <VStack align="flex-start" spacing={0}>
                  <Text fontSize={{ base: 12, lg: 16 }}>Total staked</Text>
                  {currentVault && (
                    <BallzAmountWithPrice
                      value={convertBnToNumber(
                        data?.poolTotalStakeMap[currentVault.pid] ?? 0,
                      )}
                      ballzAmountProps={{
                        numProps: {
                          fontWeight: 800,
                          fontSize: { base: '16px', lg: '24px' },
                          suffix: 'BALLZ',
                        },
                        iconProps: {
                          height: {
                            base: '16px',
                            lg: '36px',
                          },
                          width: {
                            base: '16px',
                            lg: '36px',
                          },
                        },
                        containerProps: {
                          justify: 'flex-end',
                        },
                      }}
                      usdContainerProps={{
                        height: '100%',
                      }}
                      usdValueProps={{
                        fontSize: { base: '12px', lg: '16px' },
                        fontWeight: '400',
                        maxH: {
                          base: '16px',
                          lg: '36px',
                        },
                      }}
                    />
                  )}
                </VStack>
                <Divider
                  orientation="vertical"
                  height="60px"
                  borderColor="brand.primary"
                />
                <VStack align="flex-start" spacing={0} flex={1}>
                  <Text fontSize={{ base: 12, lg: 16 }}>APY</Text>
                  <Text
                    fontSize={{ base: 16, lg: 24 }}
                    fontWeight={800}
                    color="#7338BA"
                  >
                    {formatNumber((currentVault?.apr ?? 0) * 100, 5)}%
                  </Text>
                </VStack>
              </HStack>
            </VStack>
            {stakes.length > 0 && (
              <VStack spacing={4} width="full">
                <StrokeText
                  color="brand.pink"
                  textAlign="left"
                  width="full"
                  fontSize={{ base: 20, lg: 24 }}
                  lineHeight="18px"
                  strokeWidth="4px"
                  strokeColor="#3B254C"
                >
                  You’ve staked
                </StrokeText>
                <VStack spacing={3} width="full">
                  {stakes.map((stake) => (
                    <HStack
                      key={stake.stakeTimestamp.toString()}
                      px={4}
                      py={3}
                      width="full"
                      align="center"
                      justify="space-between"
                      spacing={4}
                      bg="bg.main"
                      borderRadius={12}
                      borderColor="brand.primary"
                      borderWidth={1}
                    >
                      <HStack spacing={3}>
                        <VStack align="flex-start" spacing={0}>
                          <Text fontSize={{ base: 12, lg: 16 }}>
                            You’ve staked
                          </Text>
                          <BallzAmountWithPrice
                            value={convertBnToNumber(stake.amount)}
                            ballzAmountProps={{
                              numProps: {
                                fontWeight: 800,
                                fontSize: { base: '16px', lg: '24px' },
                                suffix: 'BALLZ',
                              },
                              iconProps: {
                                height: {
                                  base: '16px',
                                  lg: '36px',
                                },
                                width: {
                                  base: '16px',
                                  lg: '36px',
                                },
                              },
                              containerProps: {
                                justify: 'flex-end',
                              },
                            }}
                            usdContainerProps={{
                              height: '100%',
                            }}
                            usdValueProps={{
                              fontSize: { base: '12px', lg: '16px' },
                              fontWeight: '400',
                              maxH: {
                                base: '16px',
                                lg: '36px',
                              },
                            }}
                          />
                        </VStack>
                        <Divider
                          orientation="vertical"
                          height="60px"
                          borderColor="brand.primary"
                        />
                      </HStack>
                      <Flex position="relative">
                        <Button
                          height={{ base: '40px', lg: '48px' }}
                          border="3px solid #3B254C"
                          borderRadius={16}
                          boxShadow="4px 4px 0px 0px #FF7BAE"
                          bg="brand.pink"
                          isLoading={isWithdrawLoading}
                          isDisabled={!stake.isUnlocked}
                          _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                          _hover={
                            !stake.isUnlocked ? { bg: 'brand.pink' } : undefined
                          }
                          onClick={() => handleWithdraw(stake)}
                        >
                          <StrokeText
                            color="brand.primary"
                            fontSize={{ base: 18, lg: 20 }}
                            pt={2}
                            px={{ base: 3, lg: 5 }}
                            lineHeight={{ base: '16px', lg: '18px' }}
                            strokeWidth="4px"
                            strokeColor="#3B254C"
                          >
                            withdraw
                          </StrokeText>
                        </Button>
                        <UnlockedTime
                          timestamp={stake.unlockTimestamp.toNumber()}
                        />
                      </Flex>
                    </HStack>
                  ))}
                </VStack>
              </VStack>
            )}
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          <VStack width="full">
            <Button
              bg="brand.pink"
              width="full"
              height={{ base: '54px', lg: '74px' }}
              mx={8}
              borderColor="brand.purple"
              borderWidth={3}
              borderRadius={20}
              pt={4}
              pb={2.5}
              boxShadow="4px 4px 0px 0px #3B254C"
              onClick={() => {
                onClose();
                useStakeDrawer.getState().openDrawer();
              }}
            >
              <StrokeText
                color="brand.primary"
                fontSize={{ base: 30, lg: 42 }}
                lineHeight={{ base: '23px', lg: '38px' }}
                strokeWidth="6px"
                strokeColor="#3B254C"
              >
                Stake More!
              </StrokeText>
            </Button>
          </VStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
