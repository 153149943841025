import type { TextProps } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';

export type AddressProps = {
  address: string;
  leadingChars?: number;
  tailingChars?: number;
  isExpanded?: boolean;
} & TextProps;

export const Address: React.FC<AddressProps> = ({
  address,
  leadingChars = 4,
  tailingChars = 4,
  isExpanded = false,
  ...rest
}) => {
  if (!address) return null;
  if (isExpanded) return <Text {...rest}>{address}</Text>;

  return (
    <Text {...rest}>
      {address.slice(0, leadingChars)}...{address.slice(-tailingChars)}
    </Text>
  );
};
