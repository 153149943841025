import { useMutation } from '@tanstack/react-query';
import { getApiUrl } from '@ui/utils';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';

import type { DiscountType } from '../types';

const PurchaseIconUrl = 'https://ballz.game/images/purchase2.png';

export const useGetInvoiceLink = ({ tgId }: { tgId?: number }) => {
  const initData = useTgInfoStore((s) => s.initData);

  return useMutation({
    mutationKey: ['invoice_link', tgId, initData],
    mutationFn: async ({
      stars,
      balls,
      type,
    }: {
      stars: number;
      balls: number;
      type: DiscountType;
    }) => {
      if (!tgId) return;
      const res = await fetch(getApiUrl(`/payment/${tgId}/invoice-link`), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'tg-init-data': initData ?? '',
        },
        body: JSON.stringify({
          currency: 'XTR',
          star_amount: stars,
          ball_amount: balls,
          photo_url: PurchaseIconUrl,
          type,
        }),
      });

      if (!res.ok) {
        const error = await res.json();
        return Promise.reject(error);
      }

      const jsonRes = await res.json();
      return jsonRes;
    },
  });
};
