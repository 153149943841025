import type {
  Commodity,
  CommodityForChain,
  GetCommodityData,
  GetCommodityForChainData,
} from './types';
import { DiscountType } from './types';

export interface FormattedCommodity {
  orderId?: number;
  discountValue: number;
  rawValue: number;
  discount: number;
  balls: number;
  discountType: DiscountType;
  key: string;
  usdValue?: number;
  expiredTime?: number;
}

export const formatCommodities = (data?: GetCommodityData[]) => {
  const normalCommodities: FormattedCommodity[] = [];
  const epicCommodities: FormattedCommodity[] = [];
  data?.map((commodityData: GetCommodityData) => {
    commodityData.commodities.forEach((commodity: Commodity) => {
      const item = {
        rawValue: commodity.star_amount,
        discountValue: commodity.discount_star_amount,
        balls: commodity.ball_amount,
        discountType: commodityData.discount_type,
        discount: (1 - commodityData.discount) * 100,
        key: `${commodityData.discount_type}_${commodity.star_amount}_${commodity.ball_amount}`,
      };
      if (commodityData.discount_type === DiscountType.NORMAL) {
        normalCommodities.push(item);
      } else {
        epicCommodities.push(item);
      }
    });
  });
  return [normalCommodities, epicCommodities];
};

export const formatCommoditiesForChain = (
  data?: GetCommodityForChainData[],
) => {
  const normalCommodities: FormattedCommodity[] = [];
  const epicCommodities: FormattedCommodity[] = [];
  const orders: FormattedCommodity[] = [];
  data?.map((commodityData: GetCommodityForChainData) => {
    commodityData.commodities.forEach((commodity: CommodityForChain) => {
      const item = {
        rawValue: commodity.token_amount,
        discountValue: commodity.discount_token_amount,
        balls: commodity.ball_amount,
        discountType: commodityData.discount_type,
        discount: (1 - commodity.discount) * 100,
        usdValue: commodity.usd_amount,
        key: `${commodityData.discount_type}_${commodity.ball_amount}`,
        expiredTime: commodity.expired_time ?? undefined,
        orderId: commodity.order_id ?? undefined,
      };
      if (commodity.order_id) {
        orders.push(item);
      }
      if (commodityData.discount_type === DiscountType.NORMAL) {
        normalCommodities.push(item);
      } else {
        epicCommodities.push(item);
      }
    });
  });
  return [normalCommodities, epicCommodities, orders];
};
