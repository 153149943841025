import { useQuery } from '@tanstack/react-query';
import type { ErrorResponse } from '@ui/hooks/query/type';
import { useHandleError } from '@ui/hooks/useHandleError';
import { getApiUrl } from '@ui/utils';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';

import type { GetCommodityForChainData } from '../types';
import { PurchaseType } from './usePurchaseType';
import { useRefetchInterval } from './useRefetchInterval';

async function getCommodity({
  tgId,
  initData,
  chain,
  handleError,
}: {
  tgId?: number;
  initData?: string;
  chain: Omit<PurchaseType, 'STAR'>;
  handleError: (error: ErrorResponse) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<GetCommodityForChainData[]> {
  if (!tgId) {
    throw new Error('Invalid TG ID');
  }

  const res = await fetch(
    getApiUrl(`/user/${tgId}/chain/${chain.toLocaleLowerCase()}/commodities`),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'tg-init-data': initData ?? '',
      },
    },
  );

  if (!res.ok) {
    const error = await res.json();
    handleError(error);
    return Promise.reject(error);
  }
  const response = await res.json();

  return response;
}

export const useGetCommoditiesForChain = (
  chain: Omit<PurchaseType, 'STAR'>,
) => {
  const [initData, user] = useTgInfoStore((s) => [s.initData, s.user]);
  const handleError = useHandleError();
  const [refetchInterval, resetRefetchInterval] = useRefetchInterval((s) => [
    s.refetchInterval,
    s.resetRefetchInterval,
  ]);

  return useQuery(
    ['user.commoditiesForChain', chain],
    () => {
      if (chain !== PurchaseType.CRONOS && chain !== PurchaseType.CRONOSZK) {
        return;
      }
      return getCommodity({ tgId: user.id, initData, handleError, chain });
    },
    {
      enabled: !!user.id && !!initData,
      refetchInterval: refetchInterval,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        resetRefetchInterval();
      },
    },
  );
};
