import { fontFamily } from '@ballz-app/chakra';
import { DiscountBk, Steel } from '@ballz-app/icons';
import type { StackProps } from '@chakra-ui/react';
import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';

import { StrokeText } from '../StrokeText';
import { CommodityColors, PurchaseIcons } from './constants';
import { usePurchaseType } from './hooks/usePurchaseType';
import type { FormattedCommodity } from './utils';

const BaseTextStyle = {
  color: '#3B254C',
  fontFamily: fontFamily.luckiestGuy,
  pt: '5px',
};

const BallTextStyle = {
  ...BaseTextStyle,
  fontSize: '18px',
  lineHeight: '16px',
};

const SMBallTextStyle = {
  ...BaseTextStyle,
  fontSize: '14px',
  lineHeight: '12px',
};

const TextStyle = {
  md: BallTextStyle,
  sm: SMBallTextStyle,
};

export const CommodityItem = ({
  commodity,
  _onClick,
  size = 'md',
  ...props
}: {
  _onClick?: (parameter?: FormattedCommodity) => void;
  commodity: FormattedCommodity;
  size?: 'md' | 'sm';
} & StackProps) => {
  const { discountValue, rawValue, balls, discount, discountType, usdValue } =
    commodity;
  const purchaseType = usePurchaseType((s) => s.purchaseType);
  const isDiscount = useMemo(() => {
    return !!discount;
  }, [discount]);

  const commodityItemColors = useMemo(() => {
    return CommodityColors[discountType];
  }, [discountType]);

  return (
    <HStack
      borderRadius="20px"
      border="3px solid #3B254C"
      width="100%"
      cursor="pointer"
      spacing={0}
      tabIndex={1}
      _focus={{
        boxShadow: 'none',
      }}
      boxShadow="4.3px 4.3px #3B254C"
      {...props}
      onClick={() => _onClick?.(commodity)}
    >
      <HStack
        p="15px"
        flex="1"
        bg={commodityItemColors.bg}
        bgPosition="center center"
        bgRepeat="no-repeat"
        bgSize="cover"
        borderLeftRadius="16px"
        height="50px"
        position="relative"
      >
        <Text {...TextStyle[size]}>{balls} BALLS</Text>
        <Icon as={Steel} />
        {isDiscount && (
          <Box position="absolute" top="-4px" right="0px">
            <DiscountBk
              fill={commodityItemColors.discountColor}
              width="58px"
              height="58px"
            />
            <HStack position="absolute" top="18px" right="10px" spacing={0}>
              <StrokeText
                fontSize="18px"
                strokeWidth="3px"
                strokeColor="#3B254C"
                color="white"
                height="20px"
                transform="rotate(7deg)"
              >
                {discount}
              </StrokeText>
              <StrokeText
                top="6px"
                right="4px"
                position="relative"
                fontSize="12px"
                strokeWidth="2px"
                strokeColor="#3B254C"
                color="white"
                height="20px"
              >
                %
              </StrokeText>
            </HStack>
          </Box>
        )}
      </HStack>
      <VStack
        width={isDiscount ? '100px' : '90px'}
        justify="center"
        p="15px"
        bg="#F8478B"
        borderRightRadius="16px"
        height="50px"
        spacing="0"
      >
        {/* pay currency */}
        <HStack>
          <Icon as={PurchaseIcons[purchaseType]} />
          <HStack align="baseline">
            {isDiscount && (
              <StrokeText
                fontSize={size === 'md' ? '22px' : '16px'}
                strokeWidth="4px"
                strokeColor="#3B254C"
                color="#47FCE9"
                height="20px"
                pt={size === 'md' ? '0px' : '4px'}
              >
                {discountValue}
              </StrokeText>
            )}
            <Box position="relative" textAlign="center" width="fit-content">
              <Text {...TextStyle[size]}>{rawValue}</Text>
              {isDiscount && (
                <Box
                  top="50%"
                  left="-70%"
                  transform="rotate(-33deg) translate(50%, 2px)"
                  bg="#47FCE9"
                  position="absolute"
                  width="140%"
                  height="2px"
                  borderRadius="5px"
                />
              )}
            </Box>
          </HStack>
        </HStack>
        {/* usd */}
        {usdValue && (
          <HStack>
            <Text fontSize="12px" color="brand.purple">
              ~${usdValue}
            </Text>
          </HStack>
        )}
      </VStack>
    </HStack>
  );
};
