import { Cro, Star, Zkcro } from '@ballz-app/icons';
import BuyModalImg from '@public/images/first-purchase-bg.png';

import { PurchaseType } from './hooks/usePurchaseType';
import { DiscountType } from './types';

export const CommodityColors = {
  [DiscountType.FIRST_PURCHASE]: {
    bg: `url(${BuyModalImg.src})`,
    discountColor: '#47FCE9',
  },
  [DiscountType.HALF_PRICE]: {
    bg: '#F8FFD2',
    discountColor: '#F8478B',
  },
  [DiscountType.NORMAL]: {
    bg: '#F8FFD2',
    discountColor: '#F8478B',
  },
};

export const PurchaseIcons = {
  [PurchaseType.CRONOS]: Cro,
  [PurchaseType.CRONOSZK]: Zkcro,
  [PurchaseType.STAR]: Star,
};
