import { About, Earn } from '@ballz-app/icons';

import type { NavigatorItem } from './types';
import { PageName } from './types';

export const NavigatorList: NavigatorItem[] = [
  {
    name: PageName.About,
    displayName: 'About',
    path: '/',
    icon: About,
  },
  {
    name: PageName.Earn,
    displayName: 'Earn',
    path: '/earn/',
    icon: Earn,
  },
];
