export const formatNumber = (
  number: number,
  maximumFractionDigits?: number,
): string => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: maximumFractionDigits ?? 1,
  }).format(number);
};

export function formatAbbreviation(num: number): string {
  if (num >= 1000000000) {
    return (
      (Math.floor(num / 100000000) / 10).toFixed(1).replace(/\.0$/, '') + 'B'
    );
  } else if (num >= 1000000) {
    return (Math.floor(num / 100000) / 10).toFixed(1).replace(/\.0$/, '') + 'M';
  }

  return formatNumber(num);
}
