import { PostHogEvents, postHogProxy } from '@ballz-app/analytics';
import { useQueryClient } from '@tanstack/react-query';
import { useBallzToast } from '@ui/hooks/useBallzToast';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';
import { useCallback } from 'react';

import { CommodityItem } from '../CommodityItem';
import { useGetInvoiceLink } from '../hooks/useGetInvoiceLink';
import { useLoadingStore } from '../hooks/useLoadingStore';
import type { FormattedCommodity } from '../utils';

export const StarCommodity = ({
  commodity,
}: {
  commodity: FormattedCommodity;
}) => {
  const initData = useTgInfoStore((s) => s.initData);

  const { discountValue, balls, discountType } = commodity;

  const tgId = useTgInfoStore((s) => s.user.id);
  const { mutateAsync: getInvoiceLink } = useGetInvoiceLink({ tgId });
  const queryClient = useQueryClient();
  const toast = useBallzToast();
  const [showLoading, setShowLoading] = useLoadingStore((s) => [
    s.showLoading,
    s.setShowLoading,
  ]);
  const _getInvoiceLink = useCallback(async () => {
    try {
      if (showLoading) return;
      setShowLoading(true);
      const tg = window.Telegram?.WebApp;
      const response = await getInvoiceLink({
        stars: discountValue,
        balls: balls,
        type: discountType,
      });
      await tg?.openInvoice(response.invoice_link, (status: string) => {
        if (status === 'paid') {
          // status: cancelled / failed / paid
          queryClient.invalidateQueries({
            queryKey: ['game.setting', tgId, initData],
          });
          queryClient.invalidateQueries({
            queryKey: ['user.commodity', tgId, initData],
          });

          postHogProxy.captureWithoutFeature({
            event: PostHogEvents.Buy,
            capture: {
              commodity: balls,
              stars: discountValue,
            },
          });
          toast({
            status: 'success',
            description: `You have successfully bought ${balls} Ballz! Time to play!`,
          });
        } else {
          console.log('Payment failed.');
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setShowLoading(false);
    }
  }, [
    balls,
    discountType,
    discountValue,
    getInvoiceLink,
    initData,
    queryClient,
    setShowLoading,
    showLoading,
    tgId,
    toast,
  ]);

  return <CommodityItem _onClick={_getInvoiceLink} commodity={commodity} />;
};
