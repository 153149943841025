import { PostHogEvents, postHogProxy } from '@ballz-app/analytics';
import { resetWhenRouterChange_raw } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

interface useBuyModalStore {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useBuyModal = create<useBuyModalStore>(
  resetWhenRouterChange_raw((set) => ({
    isOpen: false,
    closeModal: () => {
      // reset all data
      set({
        isOpen: false,
      });
    },
    openModal: () => {
      set({
        isOpen: true,
      });

      postHogProxy.captureWithoutFeature({
        event: PostHogEvents.OpenBuyModal,
      });
    },
  })),
);
