import { resetWhenRouterChange_raw } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

import type { FormattedCommodity } from '../BuyModal/utils';

interface useOrderDetailModalStore {
  isOpen: boolean;
  showToast: boolean;
  orderItem?: FormattedCommodity;
  setOrderItem: (orderItem?: FormattedCommodity) => void;
  openModal: (orderItem: FormattedCommodity, showToast?: boolean) => void;
  closeModal: () => void;
}

export const useOrderDetailModal = create<useOrderDetailModalStore>(
  resetWhenRouterChange_raw((set) => ({
    isOpen: false,
    showToast: false,
    orderItem: undefined,
    setOrderItem: (orderItem) => {
      set({ orderItem });
    },
    closeModal: () => {
      // reset all data
      set({
        showToast: false,
        isOpen: false,
        orderItem: undefined,
      });
    },
    openModal: (orderItem: FormattedCommodity, showToast?: boolean) => {
      set({
        isOpen: true,
        orderItem,
        showToast,
      });
    },
  })),
);
