import { create } from 'zustand';

interface UseStakeDrawer {
  isOpen: boolean;
  closeDrawer: () => void;
  openDrawer: () => void;
}

export const useStakeDrawer = create<UseStakeDrawer>((set) => ({
  isOpen: false,
  closeDrawer: () => {
    set({ isOpen: false });
  },
  openDrawer: () => {
    set({ isOpen: true });
  },
}));
