import { resetWhenRouterChange_raw } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

import type { FormattedCommodity } from '../utils';

interface useCurrentPurchaseItemStore {
  purchaseItem?: FormattedCommodity;
  setPurchaseItem: (purchaseItem?: FormattedCommodity) => void;
  updatePurchaseItem: (purchaseItem?: FormattedCommodity) => void;
}

export const useCurrentPurchaseItem = create<useCurrentPurchaseItemStore>(
  resetWhenRouterChange_raw((set) => ({
    purchaseItem: undefined,
    setPurchaseItem: (purchaseItem) => {
      set({ purchaseItem });
    },
    updatePurchaseItem: (purchaseItem) => {
      set((state) => {
        if (!purchaseItem || state.purchaseItem?.key !== purchaseItem.key)
          return { ...state };
        return { ...state, purchaseItem };
      });
    },
  })),
);
