import dayjs from 'dayjs';

export const estimateLockEndDate = (lockPeriod?: number): string => {
  if (lockPeriod === undefined) return '--';
  const now = Math.trunc(Date.now() / 1000);

  return dayjs((now + lockPeriod) * 1000).format('DD MMM YYYY');
};

export const formatUnlockTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const diff = date.getTime() - new Date().getTime();
  if (diff < 0) return null;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (diff - days * (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor(
    (diff - days * (1000 * 60 * 60 * 24) - hours * (1000 * 60 * 60)) /
      (1000 * 60),
  );

  if (days > 0) {
    return `${days} days left`;
  } else if (hours > 0) {
    return `${hours} hours left`;
  } else if (minutes > 0) {
    return `${minutes} minutes left`;
  } else {
    return 'Less than a minute left';
  }
};
