import { useQuery } from '@tanstack/react-query';
import { RefetchInterval } from '@ui/utils';
import type { SafeRecord } from '@ui/utils/typeHelpers';

interface VVSResponse {
  updated_at: number;
  data: SafeRecord<
    string,
    {
      name: string;
      symbol: string;
      price: string;
      price_CRO: string;
    }
  >;
}
export const VVS_PRICE_URL = `${process.env.VVS_TOKEN_INFO}/api/tokens`;
const VVSPriceQueryKey = 'usePriceData';

export const usePriceData = () => {
  return useQuery(
    [VVS_PRICE_URL, VVSPriceQueryKey],
    async (): Promise<VVSResponse['data']> => {
      const res = await fetch(VVS_PRICE_URL);
      const { data } = await res.json();
      return data;
    },
    {
      refetchInterval: RefetchInterval.Normal,
    },
  );
};
