import { Cro, Star, Zkcro } from '@ballz-app/icons';
import {
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import { PurchaseType, usePurchaseType } from './hooks/usePurchaseType';
import { WithStar } from './WithStar';
import { WithToken } from './WithToken';

const TabsNav = [
  {
    value: PurchaseType.CRONOS,
    icon: Cro,
    label: 'Buy with',
  },
  {
    value: PurchaseType.CRONOSZK,
    icon: Zkcro,
    label: 'Buy with',
  },
  {
    value: PurchaseType.STAR,
    icon: Star,
    label: 'Buy with',
  },
];

const PanelStyle = {
  padding: 0,
  height: '100%',
  display: 'flex',
  flexDirection: 'column' as const,
};

export const PurchaseList = () => {
  const [purchaseType, setPurchaseType] = usePurchaseType((s) => [
    s.purchaseType,
    s.setPurchaseType,
  ]);

  return (
    <Tabs
      flex="1"
      display="flex"
      flexDirection="column"
      onChange={(idx: number) => {
        setPurchaseType(TabsNav[idx].value);
      }}
    >
      <TabList
        bg="#EFE2FF"
        justifyContent="center"
        width="100%"
        p="20px"
        border="none"
      >
        {TabsNav.map((tab, idx) => {
          return (
            <Tab
              p="0"
              height="50px"
              fontSize="14px"
              key={tab.value}
              bg={purchaseType === tab.value ? 'brand.primary' : 'brand.purple'}
              flex="1"
              borderLeftRadius={!idx ? '8px' : '0px'}
              borderRightRadius={idx === TabsNav.length - 1 ? '8px' : '0px'}
              outline="3px solid"
              outlineColor="brand.purple"
              fontWeight="600"
              border="none"
              color={
                purchaseType === tab.value ? 'brand.purple' : 'brand.white'
              }
            >
              {tab.label}
              <Icon as={tab.icon} boxSize="15px" ml="3px" />
            </Tab>
          );
        })}
      </TabList>

      <TabPanels p="0" height="calc(100% - 88px)" flex="1">
        <TabPanel {...PanelStyle}>
          {purchaseType === PurchaseType.CRONOS && (
            <WithToken purchaseType={PurchaseType.CRONOS} />
          )}
        </TabPanel>
        <TabPanel {...PanelStyle}>
          {purchaseType === PurchaseType.CRONOSZK && (
            <WithToken purchaseType={PurchaseType.CRONOSZK} />
          )}
        </TabPanel>
        <TabPanel {...PanelStyle}>
          {purchaseType === PurchaseType.STAR && <WithStar />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
