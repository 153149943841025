import { create } from 'zustand';

interface UseVaultDrawer {
  isOpen: boolean;
  closeDrawer: () => void;
  openDrawer: () => void;
}

export const useVaultDrawer = create<UseVaultDrawer>((set) => ({
  isOpen: false,
  closeDrawer: () => {
    set({ isOpen: false });
  },
  openDrawer: () => {
    set({ isOpen: true });
  },
}));
