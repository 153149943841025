import { create } from 'zustand';

interface useBuyModalStore {
  refetchInterval: number;
  resetRefetchInterval: () => void;
  timer: number;
  resetAll: () => void;
}

export const Refresh_Interval_Seconds = 60;
export const Refresh_interval_Milliseconds = Refresh_Interval_Seconds * 1000;
let _timerInterval: NodeJS.Timeout;

export const useRefetchInterval = create<useBuyModalStore>((set) => ({
  refetchInterval: 0,
  timer: 0,

  resetRefetchInterval: () => {
    clearInterval(_timerInterval);
    set({
      refetchInterval: Refresh_interval_Milliseconds,
      timer: Refresh_Interval_Seconds,
    });

    _timerInterval = setInterval(() => {
      set((state) => {
        if (state.timer === 0) {
          return { timer: Refresh_Interval_Seconds };
        }
        return { timer: state.timer - 1 };
      });
    }, 1000);
  },
  resetAll: () => {
    set({ refetchInterval: 0, timer: 0 });
    clearInterval(_timerInterval);
  },
}));
