import { VStack } from '@chakra-ui/react';
import { PageNavigator } from '@ui/components/PageNavigator';
import { SEOTemplate } from '@ui/SEO';
import type { NextPage } from 'next';

import { About } from './components/About';
import { Banner } from './components/Banner';
import { Footer } from './components/Footer';
import { How } from './components/How';
import { PreSale } from './components/PreSale';
import { Roadmap } from './components/Roadmap';

export const Page: NextPage = () => {
  return (
    <>
      <SEOTemplate
        title="Ballz of Steel - Drop, bounce & win!"
        description="Ballz of Steel is a digital Plinko game integrated into the Telegram platform. Participants utilize virtual tokens to drop digital balls onto a pegged board, with the final resting position determining the reward. The game offers a risk-free environment for users to engage in Plinko without wagering real money. By accumulating virtual tokens through gameplay, participants may become eligible for potential cryptocurrency token airdrops upon the token generation event."
      />
      <VStack
        backgroundImage={{
          base: "url('/images/home/bg-mobile.svg')",
          xxs: "url('/images/home/bg.svg')",
        }}
        backgroundSize="cover"
        width="full"
        minHeight="100vh"
        minWidth={{ base: 'unset', xxs: '1240px' }}
        overflowX="scroll"
        position="relative"
      >
        <Banner />
        <PreSale />
        <Roadmap />
        <About />
        <How />
        <Footer />
        <PageNavigator />
      </VStack>
    </>
  );
};
