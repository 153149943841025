import { ConnectBtn } from './ConnectBtn';
import { PageNavigatorDesktop } from './PageNavigatorDesktop';
import { PageNavigatorMobile } from './PageNavigatorMobile';

export const PageNavigator = () => {
  return (
    <>
      <ConnectBtn />
      <PageNavigatorMobile />
      <PageNavigatorDesktop />
    </>
  );
};
