import { Transaction } from '@ballz-app/icons';
import {
  Button,
  HStack,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import CloseButton from '@public/images/close-button.png';
import { useCreateOrder } from '@ui/hooks/query/useCreateOrder';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';
import { useCallback } from 'react';

import { CommodityItem } from '../BuyModal/CommodityItem';
import { useCurrentPurchaseItem } from '../BuyModal/hooks/useCurrentPurchaseItem';
import { useGetCommoditiesForChain } from '../BuyModal/hooks/useGetCommoditiesForChain';
import { usePurchaseType } from '../BuyModal/hooks/usePurchaseType';
import { CountDown } from '../BuyModal/WithToken/CountDown';
import { useOrderDetailModal } from '../OrderDetailModal/useOrderDetailModal';
import { StrokeText } from '../StrokeText';
import { useCreateOrderModal } from './useCreateOrderModal';

export const CreateOrderModal = () => {
  const { isOpen, closeModal } = useCreateOrderModal((s) => ({
    isOpen: s.isOpen,
    closeModal: s.closeModal,
  }));
  const purchaseItem = useCurrentPurchaseItem((s) => s.purchaseItem);
  const purchaseType = usePurchaseType((s) => s.purchaseType);

  const tgId = useTgInfoStore((s) => s.user.id);
  const { mutateAsync, isLoading } = useCreateOrder({ tgId });

  const { refetch } = useGetCommoditiesForChain(purchaseType);
  const onSubmit = useCallback(async () => {
    if (purchaseItem) {
      const res = await mutateAsync({
        ball_amount: purchaseItem.balls,
        channel: purchaseType.toLocaleLowerCase(),
        discount_type: purchaseItem.discountType,
      });
      const item = {
        orderId: res.order_id,
        rawValue: res.token_amount,
        discountValue: res.discount_token_amount,
        balls: res.ball_amount,
        discountType: purchaseItem.discountType,
        discount: (1 - res.discount) * 100,
        usdValue: res.usd_amount,
        expiredTime: res.expired_time,
        key: `${purchaseItem.discountType}_${res.ball_amount}`,
      };
      useOrderDetailModal.getState().openModal(item, true);
      closeModal();
      refetch();
    }
  }, [closeModal, mutateAsync, purchaseItem, purchaseType, refetch]);

  if (!purchaseItem) {
    return null;
  }

  return (
    <Modal onClose={closeModal} isOpen={isOpen} closeOnOverlayClick={false}>
      <ModalOverlay zIndex={10001} />
      <ModalContent
        w="339px"
        borderRadius="20px"
        bg="#D1FFFA"
        boxShadow="5px 4px 0px 0px #F0DCFF"
        containerProps={{
          zIndex: 10001,
        }}
      >
        <Image
          cursor="pointer"
          position="absolute"
          src={CloseButton.src}
          alt="close modal"
          right="13px"
          top="20px"
          width="40px"
          height="40px"
          onClick={closeModal}
        />
        <VStack
          bg="#D1FFFA"
          borderRadius="20px"
          padding="30px 0px"
          spacing={6}
          fontSize={16}
          lineHeight="89%"
          fontWeight={500}
          px={6}
        >
          <StrokeText
            color="brand.white"
            fontSize={25}
            lineHeight="23px"
            strokeWidth="6px"
            strokeColor="#3B254C"
            width="full"
            textAlign="left"
          >
            CREATE AN ORDER
          </StrokeText>
          <VStack width="full" spacing={0}>
            <CommodityItem commodity={purchaseItem} size="sm" />
            <CountDown refresh={refetch} />
            <HStack>
              <Icon as={Transaction} fontSize={20} />
              <Text fontSize={14} color="brand.purple" fontWeight={400}>
                You&apos;ve got 5 Mins to send CRO now and get your ballz
                moving!
              </Text>
            </HStack>
          </VStack>
          <VStack spacing={4} width="full">
            <Button
              border="3px solid #3B254C"
              borderRadius={16}
              boxShadow="4px 4px 0px 0px #FF7BAE"
              bg="brand.pink"
              position="relative"
              onClick={onSubmit}
              isDisabled={isLoading}
              pt={4}
              pb={2.5}
              w="full"
              size="lg"
              _hover={{
                boxShadow: 'unset',
              }}
              _active={{
                boxShadow: 'unset',
              }}
            >
              <HStack align="baseline">
                <StrokeText
                  color="brand.primary"
                  fontSize={21}
                  lineHeight="21px"
                  strokeWidth="6px"
                  strokeColor="#3B254C"
                >
                  Create Order!
                </StrokeText>
              </HStack>
            </Button>
            <Text fontSize={14} color="brand.grey" fontWeight={400}>
              More details shown on the next step
            </Text>
          </VStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
