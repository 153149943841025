import { Timeleft, Unlocked } from '@ballz-app/icons';
import { HStack, Text } from '@chakra-ui/react';
import { formatUnlockTimestamp } from '@ui/utils/date';

export const UnlockedTime = ({ timestamp }: { timestamp: number }) => {
  const unlockDate = formatUnlockTimestamp(timestamp);

  return (
    <HStack
      position="absolute"
      right={0}
      top={{ base: '-18px', lg: '-24px' }}
      borderRadius={4}
      bg={unlockDate ? 'brand.purple' : '#7338BA'}
      px={{ base: 1, lg: 2 }}
      py="2px"
    >
      {unlockDate ? <Timeleft /> : <Unlocked />}
      <Text
        fontSize={{ base: 12, lg: 18 }}
        color="brand.white"
        fontWeight={400}
      >
        {unlockDate ?? 'Unlocked'}
      </Text>
    </HStack>
  );
};
