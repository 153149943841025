import { resetWhenRouterChange_raw } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

interface useCreateOrderModalStore {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useCreateOrderModal = create<useCreateOrderModalStore>(
  resetWhenRouterChange_raw((set) => ({
    isOpen: false,

    closeModal: () => {
      // reset all data
      set({
        isOpen: false,
      });
    },
    openModal: () => {
      set({
        isOpen: true,
      });
    },
  })),
);
