import type { StackProps } from '@chakra-ui/react';
import { VStack } from '@chakra-ui/react';
import { StrokeText } from '@ui/components/StrokeText';
import React from 'react';

import { OrderDetail } from '../OrderDetail';
import { useOrderDetailModal } from '../OrderDetailModal/useOrderDetailModal';
import type { FormattedCommodity } from './utils';

export enum SectionType {
  Epic = 'Epic',
  Regular = 'Regular',
}

const SectionStyle = {
  [SectionType.Epic]: {
    bg: '#EFE2FF',
    spacing: '12px',
    label: 'EPIC ONE TIME DEAL!',
    width: '100%',
    px: '20px',
  },
  [SectionType.Regular]: {
    label: 'REGULAR OFFER',
    bg: '#47FCE9',
    spacing: '12px',
    px: '20px',
    width: '100%',
  },
};

const SectionLabel = {
  [SectionType.Epic]: 'EPIC ONE TIME DEAL!',
  [SectionType.Regular]: 'REGULAR OFFER',
};
export const SectionContainer = ({
  commodities,
  CommodityDetail,
  sectionType,
  children,
  orders,
  ...resetProps
}: {
  sectionType: SectionType;
  commodities: FormattedCommodity[];
  orders?: FormattedCommodity[];
  CommodityDetail: React.ComponentType<{
    commodity: FormattedCommodity;
    isDiscount: boolean;
  }>;
  children?: React.ReactNode;
} & StackProps) => {
  if (!commodities.length) return null;
  return (
    <VStack {...SectionStyle[sectionType]} {...resetProps}>
      <StrokeText
        fontSize="23px"
        strokeWidth="5px"
        strokeColor="#3B254C"
        color="white"
        height="20px"
        mb="10px"
      >
        {SectionLabel[sectionType]}
      </StrokeText>
      {orders?.map((order) => {
        return (
          <OrderDetail
            commodity={order}
            key={order.key}
            onClick={() => {
              useOrderDetailModal.getState().openModal(order);
            }}
          />
        );
      })}
      {commodities.map((commodity) => (
        <CommodityDetail
          commodity={commodity}
          key={commodity.key}
          isDiscount={!!commodity.discount}
        />
      ))}
      {children}
    </VStack>
  );
};
