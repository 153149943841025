import type { BoxProps, StackProps } from '@chakra-ui/react';
import { Box, HStack } from '@chakra-ui/react';
import BallzImg from '@public/images/presale/ballz.png';
import type { NumProps } from '@ui/components/Num';
import { Num } from '@ui/components/Num';
import type { FractionIsh } from 'bi-fraction';

export interface BallzAmountProps {
  value: FractionIsh;
  containerProps?: StackProps;
  numProps?: NumProps;
  iconProps?: BoxProps;
}
export const BallzAmount = ({
  value,
  numProps,
  iconProps,
  containerProps,
}: BallzAmountProps) => {
  return (
    <HStack width="100%" spacing={1} {...containerProps}>
      <Num value={value} fontWeight="800" isShowUnit {...numProps} />
      <Box
        bg={`url(${BallzImg.src})`}
        height="16px"
        width="16px"
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="center"
        {...iconProps}
      />
    </HStack>
  );
};
