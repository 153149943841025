import { Error } from '@ballz-app/icons';
import type { FlexProps, InputProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useControllableState,
  VStack,
} from '@chakra-ui/react';
import TokenImg from '@public/images/token.svg';
import { StrokeText } from '@ui/components/StrokeText';
import { Bn } from '@ui/utils/num';
import type { ChangeEvent, ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { usePrevious } from 'react-use';

const btns = [
  {
    title: '25%',
    value: 0.25,
  },
  {
    title: '50%',
    value: 0.5,
  },
  {
    title: '75%',
    value: 0.75,
  },
  {
    title: 'MAX',
    value: 1,
  },
];

export type NumInputProps = Omit<FlexProps, 'onChange' | 'type'> & {
  value?: string;
  onChange?: (value: string) => void;
  max?: number;
  min?: number;
  maxDecimalPlaces?: number;
  placeholder?: string;
  errorMsg?: ReactNode;
  inputMode?: InputProps['inputMode'];
  isInvalid?: boolean;
};

export const NumInput = ({
  value,
  onChange,
  min = 0,
  // set a default max value to avoid overflow
  max = Number.MAX_SAFE_INTEGER - 1,
  maxDecimalPlaces = 18,
  errorMsg,
  inputMode = 'decimal',
  isInvalid,
}: NumInputProps) => {
  const [internalValue, setInternalValue] = useControllableState({
    value,
    onChange,
  });
  const previousMaxDecimalPlaces = usePrevious(maxDecimalPlaces);

  useEffect(() => {
    if (previousMaxDecimalPlaces !== maxDecimalPlaces) {
      setInternalValue((v) =>
        v ? Bn(v).decimalPlaces(maxDecimalPlaces).toFixed() : v,
      );
    }
  }, [maxDecimalPlaces, previousMaxDecimalPlaces, setInternalValue]);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      const n = Number(val);

      // reset user input
      if (!val || Number.isNaN(n)) {
        setInternalValue('');
        return;
      }

      // reject user input
      if (getDecimalPlaces(val) > maxDecimalPlaces) {
        if (maxDecimalPlaces === 0) {
          // Special handling for integer input on mobile
          const intVal = val.split('.')[0];
          setInternalValue(intVal);
        }
        return;
      }

      // reject user input
      if (n < min || n > max) return;

      setInternalValue(val);
    },
    [min, max, maxDecimalPlaces, setInternalValue],
  );

  return (
    <VStack spacing={{ base: 3, lg: 6 }} align="flex-start" width="full">
      <StrokeText
        color="brand.pink"
        fontSize={{ base: 20, lg: 24 }}
        lineHeight={{ base: '18px', lg: '21px' }}
        strokeWidth="4px"
        strokeColor="#3B254C"
      >
        Stake amount
      </StrokeText>
      <InputGroup borderColor="brand.black" alignItems="center">
        <Input
          size="lg"
          fontSize={{ base: 16, lg: 24 }}
          placeholder="What's your count?"
          p={{ base: 3, lg: 4 }}
          pr={20}
          borderRadius={{ base: 4, lg: 12 }}
          value={internalValue}
          onChange={onInputChange}
          borderColor="#0DDCC7"
          _placeholder={{
            color: '#AAA8A8',
          }}
          _focusVisible={{
            borderColor: errorMsg ? 'text.error' : '#0DDCC7',
            bgColor: 'brand.primary',
          }}
          type="number"
          inputMode={inputMode}
          isInvalid={isInvalid}
        />
        <InputRightElement mt={1} mr={{ base: 6, lg: 12 }} gap={1}>
          <Text fontSize={{ base: 16, lg: 24 }} fontWeight={600}>
            BALLZ
          </Text>
          <Image
            height={{ base: '16px', lg: '24px' }}
            width={{ base: '16px', lg: '24px' }}
            src={TokenImg.src}
            alt="ballz"
            transform="rotate(180deg)"
          />
        </InputRightElement>
      </InputGroup>
      {errorMsg && (
        <HStack w="full">
          <Box>
            <Error />
          </Box>
          <Text color="text.error" fontWeight={400} fontSize={12}>
            {errorMsg}
          </Text>
        </HStack>
      )}
      <HStack spacing={{ base: 1, lg: 3 }}>
        {btns.map((item) => (
          <Button
            color="brand.purple"
            border="1px solid #0DDCC7"
            borderRadius="4px"
            bg="transparent"
            fontSize={{ base: 16, lg: 18 }}
            width={{ base: '64px', lg: '89px' }}
            height={{ base: '30px', lg: '42px' }}
            key={item.title}
            onClick={() => {
              setInternalValue((max * item.value).toString());
            }}
          >
            {item.title}
          </Button>
        ))}
      </HStack>
    </VStack>
  );
};

NumInput.displayName = 'NumInput';

/**
 * Calculates and returns the number of decimal places in a given numeric string.
 *
 * If the string does not contain a decimal point, the function returns 0.
 *
 * @param {string} n - The numeric string for which to calculate the number of decimal places.
 * @returns {number} The number of decimal places in the input string.
 */
function getDecimalPlaces(n: string): number {
  const dotIdx = n.indexOf('.');

  if (dotIdx === -1) return 0;

  return n.length - dotIdx - 1;
}
