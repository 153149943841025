import { DiscountBk } from '@ballz-app/icons';
import type { StackProps, TextProps } from '@chakra-ui/react';
import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import OverlayMobileBgImg from '@public/images/overlay-mobile-bg.png';
import { loadingDots } from '@ui/views/Game/components/Loading';

import { StrokeText } from '../StrokeText';

export const SellLoadingTextAndIcon = ({
  wordingProps,
}: {
  wordingProps?: TextProps;
}) => {
  return (
    <>
      <Box position="relative">
        <Box>
          <Icon as={DiscountBk} fill="#47FCE9" boxSize="115px" />
        </Box>

        <HStack position="absolute" top="40px" right="30px" spacing={0}>
          <StrokeText
            fontSize="27px"
            strokeWidth="5px"
            strokeColor="#3B254C"
            color="white"
            height="20px"
            transform="rotate(7deg)"
          >
            SALE
          </StrokeText>
        </HStack>
      </Box>
      <Text
        position="relative"
        top="-10px"
        align="center"
        width="100%"
        fontSize="20px"
        fontWeight={500}
        color="#3B264C"
        {...wordingProps}
        _after={{
          content: "'.'",
          animation: `${loadingDots} 1.5s steps(1, end) infinite`,
        }}
      >
        Loading
      </Text>
    </>
  );
};
export const SellLoadingWithMask = () => {
  return (
    <VStack
      position="absolute"
      height="100vh"
      width="100vw"
      bg="rgba(0, 0, 0, 0.8)"
      justify="center"
    >
      <SellLoadingTextAndIcon
        wordingProps={{
          color: 'white',
        }}
      />
    </VStack>
  );
};

export const SellLoading = ({ ...restProps }: StackProps) => {
  return (
    <VStack
      justify="center"
      spacing={0}
      bgImage={`url(${OverlayMobileBgImg.src})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      position="relative"
      {...restProps}
    >
      <SellLoadingTextAndIcon />
    </VStack>
  );
};
