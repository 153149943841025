import { useBindModal } from '@ui/components/BindModal/useBindModal';
import { useCreateOrderModal } from '@ui/components/CreateOrder/useCreateOrderModal';
import { useOrderDetailModal } from '@ui/components/OrderDetailModal/useOrderDetailModal';
import { useUserRanking } from '@ui/hooks/query/useUserRanking';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';
import { useCallback } from 'react';

import { CommodityItem } from '../CommodityItem';
import { useCurrentPurchaseItem } from '../hooks/useCurrentPurchaseItem';
import { DiscountType } from '../types';
import type { FormattedCommodity } from '../utils';

export const TokenCommodity = ({
  commodity,
}: {
  commodity: FormattedCommodity;
}) => {
  const { user } = useTgInfoStore();
  const { data: myRanking } = useUserRanking(user.id);
  const { openModal } = useBindModal();

  const openCreateOrderModal = useCreateOrderModal((s) => s.openModal);
  const openOrderDetailModal = useOrderDetailModal((s) => s.openModal);

  const _onClick = useCallback(
    (parameter?: FormattedCommodity) => {
      if (!myRanking?.address) {
        openModal(true);
        return;
      }
      if (!parameter || parameter.orderId) return;

      if (commodity.discountType === DiscountType.NORMAL) {
        openOrderDetailModal(parameter);
      } else {
        openCreateOrderModal();
        useCurrentPurchaseItem.getState().setPurchaseItem(parameter);
      }
    },
    [
      commodity.discountType,
      myRanking?.address,
      openCreateOrderModal,
      openModal,
      openOrderDetailModal,
    ],
  );

  return (
    <CommodityItem
      commodity={commodity}
      _onClick={_onClick}
      filter={commodity.orderId ? 'opacity(0.5)' : 'unset'}
    />
  );
};
