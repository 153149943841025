import { resetWhenRouterChange_raw } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

interface useBindModalStore {
  isOpen: boolean;
  showWarning?: boolean;
  openModal: (showWarning?: boolean) => void;
  closeModal: () => void;
}

export const useBindModal = create<useBindModalStore>(
  resetWhenRouterChange_raw((set) => ({
    isOpen: false,
    showWarning: false,
    closeModal: () => {
      // reset all data
      set({
        isOpen: false,
        showWarning: false,
      });
    },
    openModal: (showWarning?: boolean) => {
      set({
        isOpen: true,
        showWarning,
      });
    },
  })),
);
