/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Image, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import CloseButton from '@public/images/close-button.png';
import { useCallback } from 'react';

import { useBuyModal } from './hooks/useBuyModal';
import { PurchaseType, usePurchaseType } from './hooks/usePurchaseType';
import { ImagePanel } from './ImagePanel';
import { PurchaseList } from './PurchaseList';

export const BuyModal = () => {
  const [isOpen, closeModal] = useBuyModal((s) => [s.isOpen, s.closeModal]);

  const onClose = useCallback(() => {
    closeModal();
    usePurchaseType.getState().setPurchaseType(PurchaseType.CRONOS);
  }, [closeModal]);

  return (
    <Modal
      size="full"
      scrollBehavior="outside"
      onClose={onClose}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent
        containerProps={{
          zIndex: 10001,
          display: 'flex',
        }}
      >
        <Image
          cursor="pointer"
          position="absolute"
          src={CloseButton.src}
          alt="mission notification"
          right="13px"
          top="20px"
          width="60px"
          height="60px"
          onClick={onClose}
        />
        <ImagePanel />

        <PurchaseList />
      </ModalContent>
    </Modal>
  );
};
