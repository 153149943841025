import { fontFamily } from '@ballz-app/chakra';
import { Error, Steel, Timer } from '@ballz-app/icons';
import type { StackProps } from '@chakra-ui/react';
import { Box, Divider, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import OrderBg from '@public/images/order-bg.png';
import { useQueryClient } from '@tanstack/react-query';
import { useCountDown } from '@ui/hooks/useCountDown';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useEffect, useMemo, useState } from 'react';

import { PurchaseIcons } from './BuyModal/constants';
import { usePurchaseType } from './BuyModal/hooks/usePurchaseType';
import type { FormattedCommodity } from './BuyModal/utils';
import { StrokeText } from './StrokeText';

dayjs.extend(duration);

export const OrderDetail = ({
  commodity,
  isShowExpired = false,
  ...restProps
}: {
  commodity: FormattedCommodity;
  isShowExpired?: boolean;
} & StackProps) => {
  const {
    orderId,
    discountValue,
    rawValue,
    balls,
    discount,
    usdValue,
    expiredTime,
  } = commodity;
  const client = useQueryClient();
  const purchaseType = usePurchaseType((s) => s.purchaseType);

  const [disappear, setDisappear] = useState(false);
  const milliSeconds = useCountDown(expiredTime ? expiredTime * 1000 : 0);

  const isExpired = useMemo(() => {
    const isExpired = expiredTime && expiredTime < Date.now() / 1000;

    return isExpired;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milliSeconds, expiredTime]);

  useEffect(() => {
    if (isExpired && !isShowExpired) {
      setTimeout(() => {
        client.invalidateQueries(['user.commoditiesForChain', purchaseType]);
      }, 3000);
      setDisappear(true);
    }
  }, [client, isExpired, isShowExpired, purchaseType]);

  const dateFormat = useMemo(() => {
    if (milliSeconds) {
      if (milliSeconds > 1000 * 60) {
        return dayjs.duration(milliSeconds).format('mm:ss');
      } else if (milliSeconds > 0) {
        return dayjs.duration(milliSeconds).format('00:ss');
      }
    }
    return undefined;
  }, [milliSeconds]);

  const isDiscount = useMemo(() => {
    return !!discount;
  }, [discount]);

  return (
    <VStack
      sx={{
        '@keyframes fadeOrderOut': {
          '0%': {
            opacity: 1,
            height: '123px',
          },
          '50%': {
            opacity: 1,
            height: '123px',
          },
          '100%': {
            opacity: 0,
            height: '0px',
          },
        },
        animation: disappear ? 'fadeOrderOut 4s' : '',
      }}
      spacing={3}
      width="full"
      {...restProps}
    >
      <VStack
        width="full"
        bg="#E9FFFC"
        borderRadius={20}
        borderWidth={5}
        borderColor="brand.purple"
        overflow="hidden"
        boxShadow="4.375px 4.375px 0px 0px #3B254C;"
        spacing={0}
      >
        <HStack
          bgImage={`url(${OrderBg.src})`}
          bgPosition="center"
          bgSize="cover"
          bgRepeat="no-repeat"
          px={5}
          py={3}
          width="full"
          borderBottomWidth={5}
          borderBottomColor="brand.purple"
          justify="space-between"
        >
          <Text
            color="brand.purple"
            fontSize={16}
            lineHeight="14px"
            fontFamily={fontFamily.luckiestGuy}
            pt={1}
          >
            Order{orderId ? `: #${orderId}` : ''}
          </Text>
          {expiredTime ? (
            <HStack spacing={1}>
              <Icon as={Timer} />
              <Text
                fontSize={14}
                fontWeight={400}
                fontFamily={fontFamily.luckiestGuy}
                color="brand.pink"
                pt={1}
              >
                {isExpired ? 'expired' : dateFormat ?? ''}
              </Text>
            </HStack>
          ) : null}
        </HStack>
        <HStack width="full" justify="space-between" px={5}>
          <HStack flex={1}>
            <Text
              color="brand.purple"
              fontSize={16}
              fontFamily={fontFamily.luckiestGuy}
              pt="5px"
            >
              {balls} BALLS
            </Text>
            <Icon as={Steel} />
          </HStack>
          <Divider
            orientation="vertical"
            borderWidth="2.5px"
            height="54px"
            m={0}
            opacity={1}
            borderColor="brand.purple"
          />
          <VStack flex={1} spacing={0}>
            <HStack justify="center" spacing="10px">
              <Icon as={PurchaseIcons[purchaseType]} />
              <HStack>
                {isDiscount && (
                  <StrokeText
                    fontSize="16px"
                    strokeWidth="4px"
                    strokeColor="#3B254C"
                    color="#47FCE9"
                    height="16px"
                  >
                    {discountValue}
                  </StrokeText>
                )}
                <Box position="relative" textAlign="center" width="fit-content">
                  <Text
                    color="brand.purple"
                    fontSize={14}
                    fontFamily={fontFamily.luckiestGuy}
                  >
                    {rawValue}
                  </Text>
                  {isDiscount && (
                    <Box
                      top="50%"
                      left="-70%"
                      transform="rotate(-33deg) translate(50%, 0px)"
                      bg="#F8478B"
                      position="absolute"
                      width="140%"
                      height="2px"
                      borderRadius="5px"
                    />
                  )}
                </Box>
              </HStack>
            </HStack>
            {/* usd */}
            {usdValue && (
              <HStack>
                <Text fontSize="12px" fontWeight={400} color="brand.purple">
                  ~${usdValue}
                </Text>
              </HStack>
            )}
          </VStack>
        </HStack>
      </VStack>
      {isShowExpired && expiredTime && isExpired && !dateFormat ? (
        <HStack align="flex-start">
          <Icon as={Error} />
          <Text color="brand.error" fontSize={12} fontWeight={400}>
            Your order has expired! Ignore this if you already made the
            transaction, your ballz are on their way
          </Text>
        </HStack>
      ) : null}
    </VStack>
  );
};
