import { PostHogEvents, postHogProxy } from '@ballz-app/analytics';
import { fontFamily } from '@ballz-app/chakra';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import CloseButton from '@public/images/close-button.png';
import drawerBg from '@public/images/staking/drawer-bg.png';
import headerBg from '@public/images/staking/header-bg.png';
import TokenImg from '@public/images/token.svg';
import { Num } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { StrokeText } from '@ui/components/StrokeText';
import { useBallzBalance } from '@ui/hooks/contracts/useBallzBalance';
import { useBallzPrice } from '@ui/hooks/useBallzPrice';
import { estimateLockEndDate } from '@ui/utils/date';
import { limitDecimals } from '@ui/utils/formatNumber';
import { formatNumber } from '@ui/utils/formatter';
import { estimateReward } from '@ui/utils/vault';
import useBallzVaultDeposit from '@ui/views/Earn/hooks/useBallzVaultDeposit';
import { useCurrentVault } from '@ui/views/Earn/hooks/useCurrentVault';
import { RoundingMode } from 'bi-fraction';
import { isNumber } from 'lodash-es';
import { useMemo, useState } from 'react';

import { NumInput } from './NumInput';
import { useStakeDrawer } from './useStakeDrawer';

export const StakeDrawer = () => {
  const { isOpen, onClose } = useStakeDrawer((s) => ({
    isOpen: s.isOpen,
    onClose: s.closeDrawer,
  }));
  const [amount, setAmount] = useState('');
  const { data } = useBallzBalance();
  const { data: ballzPrice } = useBallzPrice();
  const { isLoading, mutateAsync } = useBallzVaultDeposit();

  const { currentVault } = useCurrentVault();
  const rewards = useMemo(
    () =>
      estimateReward(
        Number(amount),
        currentVault?.lockPeriod,
        currentVault?.apr,
      ),
    [amount, currentVault?.lockPeriod, currentVault?.apr],
  );

  const isAmtValid = useMemo(() => {
    return (
      amount === '' ||
      (!!amount &&
        Number(amount) !== 0 &&
        Number(amount) <= (Number(data?.totalAmount.toFixed(2)) || 0))
    );
  }, [amount, data?.totalAmount]);

  const isDisabled = useMemo(() => {
    if (amount === '' || !isAmtValid) return true;
    return false;
  }, [amount, isAmtValid]);

  const handleOnNext = async () => {
    if (isDisabled) return;

    await mutateAsync(
      { amount },
      {
        onSuccess: () => {
          if (!currentVault) return;

          postHogProxy.captureWithoutFeature({
            event: PostHogEvents.Stake,
            capture: {
              pid: currentVault.pid,
              amount: isNumber(amount)
                ? amount
                : Number(limitDecimals(amount, 2)),
            },
          });
        },
      },
    );
    onClose();
  };

  return (
    <Drawer
      size={{ base: 'full', lg: 'lg' }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent bg={`url(${drawerBg.src})`}>
        <DrawerHeader
          pt={{ base: '24px', lg: '32px' }}
          pb={{ base: '18px', lg: '26px' }}
          px={{ base: '16px', lg: '54px' }}
          bg={`url(${headerBg.src})`}
        >
          <StrokeText
            color="brand.purple"
            fontSize={{ base: 25, lg: 40 }}
            lineHeight="23px"
            strokeWidth="6px"
            strokeColor="#47FCE9"
          >
            {currentVault?.lockPeriodTitle} STAKING
          </StrokeText>
          <Image
            cursor="pointer"
            position="absolute"
            src={CloseButton.src}
            alt="close modal"
            right={{ base: '16px', lg: '50px' }}
            top={{ base: '12px', lg: '20px' }}
            width="40px"
            height="40px"
            onClick={onClose}
          />
        </DrawerHeader>

        <DrawerBody p={0}>
          <VStack
            align="flex-start"
            py={{ base: 6, lg: 15 }}
            px={{ base: 4, lg: 14 }}
            bg="#B7FEF6"
            spacing={6}
          >
            <NumInput
              value={amount}
              onChange={(v) => setAmount(v)}
              isInvalid={!isAmtValid}
              max={Number(
                data?.totalAmount.toFixed(2, {
                  roundingMode: RoundingMode.ROUND_DOWN,
                }),
              )}
            />
            <HStack
              px={{ base: 2, lg: 4 }}
              py={3}
              bg="#D9FFFB"
              borderRadius={8}
              width="full"
              justify="space-between"
            >
              <Text fontSize={{ base: 12, lg: 18 }}>Balance</Text>
              <HStack align="flex-start" spacing={1}>
                <VStack spacing={0} align="flex-end">
                  <Num
                    fontSize={{ base: 14, lg: 20 }}
                    fontWeight={600}
                    value={data?.totalAmount}
                    suffix="BALLZ"
                    isShowUnit
                  />
                  <UsdValue
                    fontSize={{ base: 14, lg: 16 }}
                    value={data?.totalAmount}
                    price={ballzPrice}
                    prefix="$"
                    decimalPlaces={2}
                    gap={0}
                  />
                </VStack>
                <Image
                  height={{ base: '16px', lg: '24px' }}
                  width={{ base: '16px', lg: '24px' }}
                  src={TokenImg.src}
                  alt="ballz"
                  transform="rotate(180deg)"
                />
              </HStack>
            </HStack>
          </VStack>
          <VStack
            align="flex-start"
            py={{ base: 6, lg: 15 }}
            px={{ base: 4, lg: 14 }}
            spacing={{ base: 2, lg: 7 }}
          >
            <StrokeText
              color="brand.primary"
              fontSize={{ base: 20, lg: 24 }}
              lineHeight={{ base: '18px', lg: '21px' }}
              strokeWidth="4px"
              strokeColor="#3B254C"
            >
              Projected Rewards
            </StrokeText>
            <HStack width="full">
              <Box
                border="1px solid #3B254C"
                borderRadius={6}
                boxShadow="2.522px 2.522px 0px 0px #3B254C"
                flex={1}
                overflow="hidden"
                bg="#DEFFFC"
              >
                <Text
                  height={{ base: '34px', lg: '52px' }}
                  lineHeight={{ base: '34px', lg: '52px' }}
                  fontSize={{ base: 16, lg: 22 }}
                  color="#008B7D"
                  fontFamily={fontFamily.luckiestGuy}
                  bg="brand.primary"
                  px={{ base: 2, lg: 4 }}
                >
                  APR
                </Text>
                <Box
                  height={{ base: '87px', lg: '132px' }}
                  px={{ base: 2, lg: 4 }}
                  py={3}
                >
                  <Text
                    fontWeight={800}
                    fontSize={{ base: '16px', lg: '24px' }}
                  >
                    {formatNumber((currentVault?.apr ?? 0) * 100, 5)}%
                  </Text>
                </Box>
              </Box>
              <Box
                border="1px solid #3B254C"
                borderRadius={6}
                boxShadow="2.522px 2.522px 0px 0px #3B254C"
                flex={1}
                overflow="hidden"
                bg="#DEFFFC"
              >
                <Text
                  height={{ base: '34px', lg: '52px' }}
                  lineHeight={{ base: '34px', lg: '52px' }}
                  fontSize={{ base: 16, lg: 22 }}
                  color="#008B7D"
                  fontFamily={fontFamily.luckiestGuy}
                  bg="brand.primary"
                  px={{ base: 2, lg: 4 }}
                >
                  Est. rewards
                </Text>
                <VStack
                  height={{ base: '87px', lg: '132px' }}
                  px={{ base: 2, lg: 4 }}
                  py={3}
                  align="flex-start"
                  spacing={1}
                >
                  <HStack>
                    <Num
                      fontSize={{ base: '16px', lg: '24px' }}
                      fontWeight={800}
                      value={rewards}
                      suffix="BALLZ"
                      isShowUnit
                    />
                    <Image
                      height={{ base: '16px', lg: '24px' }}
                      width={{ base: '16px', lg: '24px' }}
                      src={TokenImg.src}
                      alt="ballz"
                      transform="rotate(180deg)"
                    />
                  </HStack>
                  <UsdValue
                    fontSize={{ base: 12, lg: 16 }}
                    value={rewards}
                    price={ballzPrice}
                    prefix="$"
                    decimalPlaces={2}
                    gap={0}
                  />
                  <Text fontSize={{ base: 12, lg: 16 }}>
                    Unlocking in {estimateLockEndDate(currentVault?.lockPeriod)}
                  </Text>
                </VStack>
              </Box>
            </HStack>
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          <Button
            bg="brand.pink"
            width="full"
            height={{ base: '54px', lg: '74px' }}
            mx={8}
            borderColor="brand.purple"
            borderWidth={3}
            borderRadius={20}
            pt={4}
            pb={2.5}
            isLoading={isLoading}
            isDisabled={isDisabled}
            _disabled={{
              cursor: 'not-allowed',
              bg: '#F3A0D1',
            }}
            _hover={isDisabled ? { bg: '#F3A0D1' } : { bg: 'brand.pink' }}
            onClick={handleOnNext}
            border={isDisabled ? '3px solid #A893B7' : '3px solid #3B254C'}
            background={isDisabled ? '#F3A0D1' : '#F8478B'}
            boxShadow={
              isDisabled ? '4px 4px 0px 0px #A893B7' : '4px 4px 0px 0px #3B254C'
            }
          >
            <StrokeText
              color={isDisabled ? '#ACE9F6' : '#47FCE9'}
              fontSize={{ base: 30, lg: 42 }}
              lineHeight={{ base: '23px', lg: '38px' }}
              strokeWidth="6px"
              strokeColor={isDisabled ? '#A893B7' : '#3B254C'}
            >
              Confirm & Stake
            </StrokeText>
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
