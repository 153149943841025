import { Cro } from '@ballz-app/icons';
import { currentWallet } from '@ballz-app/wallet';
import { Button, HStack, Icon, Image, Text, VStack } from '@chakra-ui/react';
import TokenImg from '@public/images/token.svg';
import { useBallzBalance } from '@ui/hooks/contracts/useBallzBalance';
import { useBallzPrice } from '@ui/hooks/useBallzPrice';
import { useCroPrice } from '@ui/hooks/useCroPrice';
import { useWalletBalance } from '@ui/hooks/useWalletBalance';

import { CurrentWalletIcon } from '../CurrentWalletIcon';
import { Num } from '../Num';
import { UsdValue } from '../Num/UsdValue';
import { useWalletModal } from './WalletModal';

export const ConnectedBody = () => {
  const closeModal = useWalletModal((s) => s.closeModal);
  const balance = useWalletBalance();
  const { data } = useBallzBalance();

  const { data: price } = useCroPrice();
  const { data: ballzPrice } = useBallzPrice();

  return (
    <VStack spacing={6} align="flex-start" pt={4}>
      <HStack>
        <CurrentWalletIcon />
        <Text
          color="brand.purple"
          fontSize={16}
          lineHeight="89%"
          fontWeight={500}
          w={{ base: '246px', lg: '360px' }}
        >
          {currentWallet.useAccount()}
        </Text>
      </HStack>
      <VStack width="full">
        <HStack
          spacing={1.5}
          p={3}
          justify="space-between"
          width="full"
          bg="#B7FEF6"
          borderRadius={8}
          borderColor="brand.primary"
          borderWidth={1}
        >
          <HStack>
            <Image
              height="16px"
              width="16px"
              src={TokenImg.src}
              alt="ballz"
              transform="rotate(180deg)"
            />
            <Text>BALLZ</Text>
          </HStack>
          <HStack fontSize={14} fontWeight={600} spacing={1}>
            <Num value={data?.totalAmount} />
            <UsdValue
              value={data?.totalAmount}
              price={ballzPrice}
              prefix="($"
              suffix=")"
              decimalPlaces={2}
              gap={0}
            />
          </HStack>
        </HStack>
        <HStack
          spacing={1.5}
          p={3}
          justify="space-between"
          width="full"
          bg="#B7FEF6"
          borderRadius={8}
          borderColor="brand.primary"
          borderWidth={1}
        >
          <HStack>
            <Icon as={Cro} boxSize="18px" />
            <Text>CRO</Text>
          </HStack>
          <HStack fontSize={14} fontWeight={600} spacing={1}>
            <Num value={balance} />
            <UsdValue
              value={balance}
              price={price}
              prefix="($"
              suffix=")"
              decimalPlaces={2}
              gap={0}
            />
          </HStack>
        </HStack>
      </VStack>
      <Button
        color="brand.pink"
        variant="link"
        onClick={async () => {
          await currentWallet.disconnect();
          closeModal();
        }}
      >
        Disconnect →
      </Button>
    </VStack>
  );
};
