import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getApiUrl } from '@ui/utils';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';

import { useHandleError } from '../useHandleError';

export const useCreateOrder = ({ tgId }: { tgId?: number }) => {
  const queryClient = useQueryClient();
  const initData = useTgInfoStore((s) => s.initData);

  const handleError = useHandleError();

  return useMutation({
    mutationKey: ['create-order', tgId, initData],
    mutationFn: async ({
      ball_amount,
      channel,
      discount_type,
    }: {
      ball_amount: number;
      channel: string;
      discount_type: string;
    }) => {
      const res = await fetch(
        getApiUrl(`/payment/${tgId}/${channel}/token-invoice`),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'tg-init-data': initData ?? '',
          },
          body: JSON.stringify({
            discount_type,
            ball_amount,
          }),
        },
      );

      if (!res.ok) {
        const error = await res.json();
        handleError(error);
        return Promise.reject(error);
      }

      const jsonRes: {
        order_id: number;
        discount: number;
        ball_amount: number;
        token_amount: number;
        discount_token_amount: number;
        usd_amount: number;
        expired_time: number;
      } = await res.json();
      return jsonRes;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['game.setting', tgId, initData]);
      return data;
    },
  });
};
