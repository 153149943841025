import { currentWallet, getAddEthereumChainParameter } from '@ballz-app/wallet';
import { useToast } from '@chakra-ui/react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

export const useSwitchNetwork = (options: UseMutationOptions = {}) => {
  const isConnected = currentWallet.useIsConnected();
  const toast = useToast();

  return useMutation(async () => {
    const networkConfig = getAddEthereumChainParameter(
      Number(process.env.CHAIN_ID),
    );
    if (!isConnected) return;

    try {
      await currentWallet.connect(networkConfig);
    } catch (e: unknown) {
      toast({
        status: 'error',
        position: 'top',
        description: e instanceof Error ? e.message : undefined,
      });
    }
  }, options);
};
