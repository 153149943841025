import { currentWallet } from '@ballz-app/wallet';
import type { ImageProps } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';

type CurrentWalletIconProps = Omit<ImageProps, 'src' | 'alt'>;

export const CurrentWalletIcon: React.FC<CurrentWalletIconProps> = (props) => {
  const walletConfig = currentWallet.useWalletConfig();

  return (
    <Image
      src={walletConfig.icon}
      alt={walletConfig.title}
      width="24px"
      height="24px"
      {...props}
    />
  );
};

CurrentWalletIcon.displayName = 'CurrentWalletIcon';
