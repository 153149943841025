import type { StackProps } from '@chakra-ui/react';
import { HStack } from '@chakra-ui/react';
import type { UsdValueProps } from '@ui/components/Num/UsdValue';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { useBallzPrice } from '@ui/hooks/useBallzPrice';

import type { BallzAmountProps } from './BallzAmount';
import { BallzAmount } from './BallzAmount';

export const BallzAmountWithPrice = ({
  value,
  ballzAmountProps,
  usdValueProps,
  usdContainerProps,
}: {
  value: number;
  ballzAmountProps?: Omit<BallzAmountProps, 'value'>;
  usdValueProps?: Omit<UsdValueProps, 'value'>;
  usdContainerProps?: StackProps;
}) => {
  const { data: ballzPrice } = useBallzPrice();

  return (
    <>
      <BallzAmount value={value} {...ballzAmountProps} />
      <HStack
        alignSelf="flex-start"
        height="fit-content"
        {...usdContainerProps}
      >
        <UsdValue
          price={ballzPrice}
          value={value}
          fontWeight="400"
          fontSize="12px"
          suffix=""
          prefix="$"
          gap={0}
          height="100%"
          {...usdValueProps}
        />
      </HStack>
    </>
  );
};
