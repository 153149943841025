import { Box } from '@chakra-ui/react';
import { useMemo } from 'react';

import { useGetCommodities } from '../hooks/useGetCommodities';
import { useLoadingStore } from '../hooks/useLoadingStore';
import { SectionContainer, SectionType } from '../SectionContainer';
import { SellLoading, SellLoadingWithMask } from '../SellLoading';
import { formatCommodities } from '../utils';
import { Disclaimer } from './Disclaimer';
import { StarCommodity } from './StarCommodity';

export const WithStar = () => {
  const { data: commodities, isLoading } = useGetCommodities();
  const [normalCommodities, epicCommodities] = useMemo(() => {
    return formatCommodities(commodities);
  }, [commodities]);
  const showLoading = useLoadingStore((s) => s.showLoading);

  return (
    <>
      {isLoading ? (
        <SellLoading flex="1" height="100%" width="100%" />
      ) : (
        <Box flex="1" height="100%" overflow="scroll">
          {/* Epic */}
          <SectionContainer
            sectionType={SectionType.Epic}
            commodities={epicCommodities}
            CommodityDetail={StarCommodity}
          />

          {/* regular */}
          <SectionContainer
            sectionType={SectionType.Regular}
            commodities={normalCommodities}
            CommodityDetail={StarCommodity}
            pt="20px"
          />
        </Box>
      )}

      <Disclaimer />
      {showLoading && <SellLoadingWithMask />}
    </>
  );
};
