import { getBallzAddressByEnv } from './getBallzAddressByEnv';

export const REFER_KEY = 'refer';

export enum RefetchInterval {
  // 10 seconds
  Fast = 10_000,
  // 20 seconds
  Normal = 20_000,
  // 1 minutes
  Slow = 60_000,
}

export const vvsSwapLink = `${
  process.env.VVS_FINANCE_SWAP
}?outputCurrency=${getBallzAddressByEnv()}`;
