import { useQuery } from '@tanstack/react-query';
import { getApiUrl } from '@ui/utils';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';

import { useHandleError } from '../useHandleError';
import type { ErrorResponse } from './type';

export interface UserRankingResponse {
  rank: number;
  user_id: string;
  username: string | null;
  point: number;
  tier: string;
  address: string | null;
}

async function getUserRanking({
  tgId,
  initData,
  handleError,
}: {
  tgId: number | undefined;
  initData?: string;
  handleError: (error: ErrorResponse) => void;
}): Promise<UserRankingResponse> {
  if (!tgId) {
    throw new Error('Invalid TG ID');
  }
  const res = await fetch(getApiUrl(`/user/${tgId}/rank`), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'tg-init-data': initData ?? '',
    },
  });
  if (!res.ok) {
    const error = await res.json();
    handleError(error);
    return Promise.reject(error);
  }
  const response = await res.json();

  return response;
}

export const useUserRanking = (tgId: number | undefined) => {
  const initData = useTgInfoStore((s) => s.initData);
  const handleError = useHandleError();

  return useQuery(
    ['userRanking', tgId, initData],
    () => getUserRanking({ tgId, initData, handleError }),
    { enabled: !!tgId && !!initData },
  );
};
