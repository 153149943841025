import { fontFamily } from '@ballz-app/chakra';
import { Box, HStack, Text } from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { NavigatorList } from './constants';

export const PageNavigatorDesktop = () => {
  const pathname = usePathname();
  const router = useRouter();

  return (
    <Box
      width="100vw"
      position="absolute"
      display={{
        base: 'none',
        lg: 'block',
      }}
      left={0}
      top="45px"
      px="60px"
      zIndex={30}
    >
      <HStack alignItems="baseline">
        {NavigatorList.map((item) => {
          return (
            <Box
              key={item.name}
              onClick={() => {
                router.push(item.path);
              }}
              mr="45px"
              cursor="pointer"
            >
              <Text
                height="30px"
                color="#47FCE9"
                fontSize="24px"
                textTransform="uppercase"
                fontFamily={fontFamily.luckiestGuy}
                borderBottom="solid #47FCE9"
                borderBottomWidth={pathname === item.path ? '3px' : '0px'}
              >
                {item.displayName}
              </Text>
            </Box>
          );
        })}
      </HStack>
    </Box>
  );
};
