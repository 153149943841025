import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import CloseButton from '@public/images/close-button.png';
import { useSwitchNetwork } from '@ui/hooks/useSwitchNetwork';
import { create } from 'zustand';

import { StrokeText } from './StrokeText';

interface UseSwitchNetworkModal {
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
}

export const useSwitchNetworkModal = create<UseSwitchNetworkModal>((set) => ({
  isOpen: false,
  closeModal: () => {
    set({ isOpen: false });
  },
  openModal: () => {
    set({ isOpen: true });
  },
}));

export const SwitchNetworkModal = () => {
  const { mutate: switchNetwork, isLoading: isSwitchingNetwork } =
    useSwitchNetwork();
  const isOpen = useSwitchNetworkModal((s) => s.isOpen);
  const closeModal = useSwitchNetworkModal((s) => s.closeModal);

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent
        borderRadius="15px"
        bg="#D1FFFA"
        box-shadow="5px 4px 0px 0px #F0DCFF"
      >
        <ModalHeader pt="30px" px="24px">
          <StrokeText
            color="brand.white"
            fontSize={25}
            lineHeight="23px"
            strokeWidth="6px"
            strokeColor="#3B254C"
          >
            Wrong Network
          </StrokeText>
          <Image
            cursor="pointer"
            position="absolute"
            src={CloseButton.src}
            alt="close modal"
            right="24px"
            top="20px"
            width="40px"
            height="40px"
            onClick={closeModal}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <VStack spacing={6}>
            <Text fontWeight={500}>
              Switch to the Cronos EVM chain to get started with Ballz of Steel!
            </Text>
            <Button
              padding={{
                base: ' 16px 20px 10px 20px',
                lg: '23px 29px 14px 29px',
              }}
              height={{ base: '40px', lg: '66px' }}
              borderRadius={{ base: '16px', lg: '29px' }}
              borderColor="#3B254C"
              borderWidth={{ base: 3, lg: 4 }}
              background="#F8478B"
              boxShadow="4px 4px 0px 0px #47FCE9"
              onClick={() => {
                if (isSwitchingNetwork) return;
                switchNetwork();
                closeModal();
              }}
            >
              <StrokeText
                color="brand.primary"
                fontSize={{ base: '20px', lg: '30px' }}
                lineHeight="89%"
                strokeWidth="4px"
                strokeColor="#3B254C"
                fontWeight="400"
              >
                Switch to cronos EVM
              </StrokeText>
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
