import { postHogProxy } from '@ballz-app/analytics';
import { currentWallet } from '@ballz-app/wallet';
import { NavigatorList } from '@ui/components/PageNavigator/constants';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { useMount } from 'react-use';

export const useInitializeWallet = () => {
  const account = currentWallet.useAccount();
  const walletName = currentWallet.useWalletName();
  const pathname = usePathname();

  useMount(() => {
    if (NavigatorList.find((item) => item.path === pathname))
      currentWallet.autoConnect();
  });

  // [PostHog] Identify user when wallet changes
  useEffect(() => {
    if (account && postHogProxy.isTrackingWallet(account)) return;

    postHogProxy.stopTrackUser();
    if (!account) return;

    postHogProxy.trackUserByWallet(account);
    postHogProxy.registerWalletName(walletName);
  }, [account, walletName]);
};
