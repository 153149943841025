import { Close, Steel } from '@ballz-app/icons';
import { explorers, useDesiredChain } from '@ballz-app/wallet';
import type { ToastId, UseToastOptions } from '@chakra-ui/react';
import {
  Divider,
  Flex,
  Icon,
  Link,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';

type ToastOptions = UseToastOptions & { hash?: string };

interface ToastTemplateProps {
  id?: ToastId;
  onClose?: () => void;
  tostOptions: ToastOptions;
  hash?: string;
}
const emptyFunction = () => void 0;

const getColors = (status: ToastOptions['status']) => {
  switch (status) {
    case 'error':
      return {
        borderColor: 'brand.error',
        bg: 'bg.pink',
      };
    case 'loading':
      return {
        borderColor: '#FFD582',
        bg: 'bg.lightYellow',
      };
    default:
      return {
        borderColor: 'brand.primary',
        bg: 'bg.main',
      };
  }
};
const Render = ({
  onClose = emptyFunction,
  tostOptions,
}: ToastTemplateProps) => {
  const chainId = useDesiredChain();
  const BLOCK_EXPLORER_URL = explorers[chainId];
  const { status, hash } = tostOptions;
  const colors = useMemo(() => {
    return getColors(status);
  }, [status]);
  return (
    <Flex
      maxWidth="350px"
      p={4}
      borderRadius={20}
      borderWidth={2}
      borderColor={colors.borderColor}
      bg={colors.bg}
      alignItems="center"
      gap={3}
      mb="92px"
    >
      {tostOptions.icon ?? <Steel fontSize="20px" style={{ flexShrink: 0 }} />}
      <VStack spacing={0} align="flex-start">
        <Text fontSize={16} lineHeight="16px" color="brand.purple">
          {tostOptions.description}
        </Text>
        {hash && (
          <Link isExternal href={`${BLOCK_EXPLORER_URL}/tx/${hash}`}>
            <Text
              fontSize={16}
              lineHeight="16px"
              color="brand.purple"
              textDecoration="underline"
            >
              View on Cronoscan
            </Text>
          </Link>
        )}
      </VStack>
      <Divider
        orientation="vertical"
        borderColor={colors.borderColor}
        height="36px"
      />
      {tostOptions.isClosable !== false && (
        <Icon
          onClick={onClose}
          cursor="pointer"
          as={Close}
          fontSize={12}
          color="brand.purple"
        />
      )}
    </Flex>
  );
};

const defaultOptions: Partial<ToastOptions> = {
  position: 'bottom',
  isClosable: true,
  duration: 3000,
};

export const useBallzToast = () => {
  const chakraToast = useToast();

  const toast = useCallback(
    (props: ToastOptions) => {
      const containerStyle: ToastOptions['containerStyle'] = {
        ...props.containerStyle,
        /**
         * Reset containerStyle
         */
        minWidth: 'unset',
        marginLeft: '0px',
        marginRight: '0px',
        marginBottom: '0px',
        maxWidth: '100vw',
        wordBreak: 'break-word',
        zIndex: 200000,
      };
      chakraToast.closeAll();

      return chakraToast({
        ...defaultOptions,
        containerStyle,
        render: (renderProps) => {
          return <Render tostOptions={props} {...renderProps} />;
        },
        ...props,
      });
    },
    [chakraToast],
  );

  return toast;
};
