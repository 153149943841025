import { create } from 'zustand';

import type { VaultCardInfo } from '../types';

interface UseCurrentVault {
  currentVault: VaultCardInfo | null;
  setCurrentVault: (vault: VaultCardInfo) => void;
  inputTokenAmount: string;
  setInputTokenAmount: (amount: string) => void;
}

export const useCurrentVault = create<UseCurrentVault>((set) => ({
  currentVault: null,
  setCurrentVault: (vault: VaultCardInfo) => set({ currentVault: vault }),
  inputTokenAmount: '',
  setInputTokenAmount: (amount: string) => set({ inputTokenAmount: amount }),
}));
